import { gql } from '@apollo/client';

export const GET_JOB = gql`
  query GetJob($_id: String!) {
    job(_id: $_id) {
      _id
      name
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const GET_JOBS = gql`
  query GetJobs {
    jobs {
      _id
      name
      createdBy
      createdAt
    }
  }
`;
