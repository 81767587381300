import { XMarkIcon } from '@heroicons/react/20/solid';
import { AnimatePresence, motion } from 'framer-motion';

import Button from '../buttons/Button';
import Backdrop from './sharedComponents/Backdrop';

const variants = {
  initial: {
    opacity: 0,
    scale: 0.75,
    y: 50
  },
  animate: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      type: 'spring'
    }
  },
  exit: {
    opacity: 0,
    scale: 0,
    transition: {
      duration: 0.15,
      ease: 'easeIn'
    }
  }
};

function Modal({ open, closeModal, title, description, children, onExitComplete }) {
  return (
    <AnimatePresence initial={false} mode="wait" onExitComplete={onExitComplete}>
      {open && (
        <div className="relative z-10">
          <Backdrop onClick={closeModal}>
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <motion.div
                  variants={variants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="w-full min-w-full max-w-3xl transform overflow-hidden divide-y divide-gray-200 rounded-2xl bg-white text-left align-middle shadow-xl">
                    <div className="flex items-center justify-between bg-gray-50 p-4 space-x-4">
                      <div className="text-base font-bold tracking-tight text-gray-700">
                        {title}
                      </div>
                      <Button
                        rounded
                        onClick={() => closeModal()}
                        Icon={XMarkIcon}
                        color="gray"
                        backgroundColor="bg-transparent"
                      />
                    </div>
                    <div className="space-y-4 p-4">
                      <div className="text-sm font-medium text-gray-500">{description}</div>
                      {children}
                    </div>
                    <div className="bg-gray-100 p-4 sm:flex sm:flex-row-reverse">
                      <Button label="Cancel" onClick={closeModal} backgroundColor="bg-gray-400" />
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </Backdrop>
        </div>
      )}
    </AnimatePresence>
  );
}

export default Modal;
