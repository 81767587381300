export const getBackground = (color) => {
  switch (color) {
    case 'slate':
      return 'bg-slate-100';
    case 'neutral':
      return 'bg-neutral-100';
    case 'zinc':
      return 'bg-zinc-100';
    case 'gray':
      return 'bg-gray-100';
    case 'stone':
      return 'bg-stone-100';
    case 'red':
      return 'bg-red-100';
    case 'green':
      return 'bg-green-100';
    case 'rose':
      return 'bg-rose-100';
    case 'purple':
      return 'bg-purple-100';
    case 'orange':
      return 'bg-orange-100';
    case 'amber':
      return 'bg-amber-100';
    case 'yellow':
      return 'bg-yellow-100';
    case 'lime':
      return 'bg-lime-100';
    case 'emerald':
      return 'bg-emerald-100';
    case 'teal':
      return 'bg-teal-100';
    case 'cyan':
      return 'bg-cyan-100';
    case 'sky':
      return 'bg-sky-100';
    case 'blue':
      return 'bg-blue-100';
    case 'indigo':
      return 'bg-indigo-100';
    case 'pink':
      return 'bg-pink-100';
    case 'violet':
      return 'bg-violet-100';
    case 'black':
      return 'bg-black';
    case 'white':
      return 'bg-white';
    case 'transparent':
      return 'bg-transparent';
    default:
      return 'bg-orange-100';
  }
};

export const getIndicator = (color) => {
  switch (color) {
    case 'slate':
      return 'bg-slate-500';
    case 'neutral':
      return 'bg-neutral-500';
    case 'zinc':
      return 'bg-zinc-500';
    case 'gray':
      return 'bg-gray-500';
    case 'stone':
      return 'bg-stone-500';
    case 'red':
      return 'bg-red-500';
    case 'green':
      return 'bg-green-500';
    case 'rose':
      return 'bg-rose-500';
    case 'purple':
      return 'bg-purple-500';
    case 'orange':
      return 'bg-orange-500';
    case 'amber':
      return 'bg-amber-500';
    case 'yellow':
      return 'bg-yellow-500';
    case 'lime':
      return 'bg-lime-500';
    case 'emerald':
      return 'bg-emerald-500';
    case 'teal':
      return 'bg-teal-500';
    case 'cyan':
      return 'bg-cyan-500';
    case 'sky':
      return 'bg-sky-500';
    case 'blue':
      return 'bg-blue-500';
    case 'indigo':
      return 'bg-indigo-500';
    case 'pink':
      return 'bg-pink-500';
    case 'violet':
      return 'bg-violet-500';
    case 'black':
      return 'bg-black';
    case 'white':
      return 'bg-white';
    case 'transparent':
      return 'bg-transparent';
    default:
      return 'bg-orange-500';
  }
};

export const getColor = (color) => {
  switch (color) {
    case 'slate':
      return 'text-slate-800';
    case 'neutral':
      return 'text-neutral-800';
    case 'zinc':
      return 'text-zinc-800';
    case 'gray':
      return 'text-gray-800';
    case 'stone':
      return 'text-stone-800';
    case 'red':
      return 'text-red-800';
    case 'green':
      return 'text-green-800';
    case 'rose':
      return 'text-rose-800';
    case 'purple':
      return 'text-purple-800';
    case 'orange':
      return 'text-orange-800';
    case 'amber':
      return 'text-amber-800';
    case 'yellow':
      return 'text-yellow-800';
    case 'lime':
      return 'text-lime-800';
    case 'emerald':
      return 'text-emerald-800';
    case 'teal':
      return 'text-teal-800';
    case 'cyan':
      return 'text-cyan-800';
    case 'sky':
      return 'text-sky-800';
    case 'blue':
      return 'text-blue-800';
    case 'indigo':
      return 'text-indigo-800';
    case 'pink':
      return 'text-pink-800';
    case 'violet':
      return 'text-violet-800';
    case 'black':
      return 'text-black';
    case 'white':
      return 'text-white';
    case 'transparent':
      return 'text-transparent';
    default:
      return 'text-orange-800';
  }
};

export const getBorder = (color) => {
  switch (color) {
    case 'slate':
      return 'border-slate-200';
    case 'neutral':
      return 'border-neutral-200';
    case 'zinc':
      return 'border-zinc-200';
    case 'gray':
      return 'border-gray-200';
    case 'stone':
      return 'border-stone-200';
    case 'red':
      return 'border-red-200';
    case 'green':
      return 'border-green-200';
    case 'rose':
      return 'border-rose-200';
    case 'purple':
      return 'border-purple-200';
    case 'orange':
      return 'border-orange-200';
    case 'amber':
      return 'border-amber-200';
    case 'yellow':
      return 'border-yellow-200';
    case 'lime':
      return 'border-lime-200';
    case 'emerald':
      return 'border-emerald-200';
    case 'teal':
      return 'border-teal-200';
    case 'cyan':
      return 'border-cyan-200';
    case 'sky':
      return 'border-sky-200';
    case 'blue':
      return 'border-blue-200';
    case 'indigo':
      return 'border-indigo-200';
    case 'pink':
      return 'border-pink-200';
    case 'violet':
      return 'border-violet-200';
    case 'black':
      return 'border-black';
    case 'white':
      return 'border-white';
    case 'transparent':
      return 'border-transparent';
    default:
      return 'border-orange-200';
  }
};
