import { useMsal } from '@azure/msal-react';
import { includes } from 'lodash';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ roles, children }) {
  const { accounts } = useMsal();

  if (includes(roles, accounts[0]?.idTokenClaims?.roles?.[0])) {
    return children;
  }

  return <Navigate to="/" replace />;
}

export default ProtectedRoute;
