import { useMutation } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import { GlobeAltIcon, PaperAirplaneIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Button from '../../../../components/buttons/Button';
import TextField from '../../../../components/inputs/TextField';
import { useToast } from '../../../../components/toast/ToastProvider';
import { CREATE_DOMAIN, UPDATE_DOMAIN } from '../../../../graphql/domains/mutations';
import { GET_DOMAINS } from '../../../../graphql/domains/queries';

function Form({ onSuccess, data }) {
  const { _id } = useParams();
  const { accounts } = useMsal();
  const { toast } = useToast();
  const [createDomain] = useMutation(CREATE_DOMAIN, {
    refetchQueries: [{ query: GET_DOMAINS }, 'GetDomains']
  });
  const [updateDomain] = useMutation(UPDATE_DOMAIN, {
    refetchQueries: [{ query: GET_DOMAINS }, 'GetDomains']
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      createdBy: accounts[0]?.username,
      updatedBy: accounts[0]?.username
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Enter correct domain! E.g. domain.com'
        )
        .required('Please enter domain name.')
    }),
    onSubmit: async (values, actions) => {
      if (_id) {
        const variables = omit(
          {
            ...values,
            _id,
            updatedAt: new Date()
          },
          'createdBy'
        );
        try {
          const result = await updateDomain({ variables });
          if (result.data.updateDomain) {
            if (onSuccess) {
              await onSuccess();
            }
            toast({
              title: 'Domain Name Updated',
              description: `The domain name has been successfully updated at ${dayjs().format(
                'LLL'
              )}.`
            });
          }
        } catch (err) {
          toast({
            title: 'Error',
            description: err.message,
            type: 'error'
          });
        }
      } else {
        try {
          const result = await createDomain({ variables: values });
          if (result.data.createDomain) {
            toast({
              title: 'Domain Name created',
              description: 'The domain name has been successfully created.'
            });
            if (onSuccess) {
              onSuccess();
            }
            actions.resetForm();
          }
        } catch (err) {
          toast({
            title: 'Error',
            description: err?.message,
            type: 'error'
          });
        }
      }
      actions.setSubmitting(false);
    }
  });

  useEffect(() => {
    if (data) {
      formik.setFieldValue('name', data.name);
    }
  }, [data]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      noValidate
      className="space-y-4 bg-orange-50 rounded-md p-4"
    >
      <TextField
        name="name"
        label="Domain"
        type="url"
        required
        placeholder="domain.com.."
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
        Icon={GlobeAltIcon}
        autoComplete="off"
        error={formik.touched.name && formik.errors.name ? formik.errors.name : null}
      />
      <Button
        type="submit"
        Icon={PaperAirplaneIcon}
        label="Submit"
        loading={formik.isSubmitting}
        disabled={formik.isSubmitting}
      />
    </form>
  );
}

export default Form;
