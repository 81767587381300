import { useCallback, useState } from 'react';

export const useToasts = () => {
  const [toasts, setToasts] = useState([]);

  const toast = useCallback((payload) => {
    const id = Date.now();

    const removeToast = () => {
      setToasts((t) => {
        return t.filter((n) => n.id !== id);
      });
    };

    setToasts((toasts) => [
      ...toasts,
      {
        id,
        onClose: removeToast,
        ...payload
      }
    ]);
  }, []);

  return { toast, toasts };
};
