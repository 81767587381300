import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import image from '../../assets/img/banner-image.png';

function Home() {
  useTitle('Information Technology | Home');
  const navigate = useNavigate();

  return (
    <div className="h-full flex flex-1 justify-center items-center bg-gradient-to-r from-amber-500 to-orange-500 p-8">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8">
        <div className="text-white flex flex-col flex-1 justify-center items-center lg:items-end">
          <div className="w-full max-w-2xl">
            <motion.div
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{
                duration: 0.2,
                type: 'spring',
                stiffness: 100
              }}
            >
              <div className="text-xl lg:text-3xl font-extrabold tracking-tight">New</div>
              <div className="text-2xl lg:text-5xl font-extrabold tracking-tight">
                Employee Request
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.2,
                type: 'spring',
                stiffness: 100
              }}
              className="text-sm lg:text-lg my-4 lg:my-8"
            >
              The Employee Requests form is utilized within our organization to oversee the creation
              of new user accounts in MS Office 365
            </motion.div>
            <motion.button
              whileHover={{}}
              onClick={() => navigate('/employees/requests')}
              className="text-sm flex items-center justify-between space-x-2 transform font-semibold tracking-tight bg-orange-500 hover:bg-opacity-50 p-4 rounded-md transition duration-500 ease-in-out"
            >
              <span>Fill a request now</span>
              <ArrowRightIcon className="w-4 h-4" />
            </motion.button>
          </div>
        </div>
        <motion.img
          initial={{ y: 0 }}
          animate={{ y: -10 }}
          exit={{ y: 0 }}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatType: 'reverse'
          }}
          src={image}
          alt="Banner Image"
          className="h-auto w-auto"
        />
      </div>
    </div>
  );
}

export default Home;
