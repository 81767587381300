import { Link } from 'react-router-dom';
import { useTitle } from 'react-use';

function NotFound() {
  useTitle('Information Technology | 404 Not Found');

  return (
    <div className="flex flex-1 items-center justify-center bg-gradient-to-r from-amber-500 to-orange-500 border-b border-orange-300 py-12 font-mono">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8">
        <div className="py-16">
          <div className="text-center">
            <div className="text-xl font-bold tracking-tight text-white">404</div>
            <div className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
              Page not found.
            </div>
            <div className="mt-2 text-base text-orange-600">
              Sorry, we could not find the page you’re looking for.
            </div>
            <div className="mt-6">
              <Link to="/" className="text-base font-medium text-white hover:text-orange-500">
                Go back home
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default NotFound;
