import { Popover } from '@headlessui/react';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/20/solid';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import Avatar from '../avatar/Avatar';

function Profile({
  handleLogout,
  name,
  email,
  officeLocation,
  jobTitle,
  role,
  photo,
  visible = false
}) {
  return (
    <AnimatePresence initial={false}>
      {visible && (
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{
            opacity: 0,
            x: 50
          }}
          transition={{ duration: 0.2, type: 'spring', stiffness: 100 }}
        >
          <Popover as="div" className="relative">
            {({ close }) => (
              <>
                <div>
                  <Popover.Button className="flex items-center py-3 px-3 justify-center rounded-2xl bg-orange-300/40 hover:bg-orange-300/20 text-[11px] ring-2 ring-orange-300/40 ring-offset-2 ring-offset-orange-400 outline-none focus:outline-none transition ease-in-out duration-500 lg:space-x-2">
                    <div className="hidden lg:flex lg:flex-col text-right uppercase font-medium">
                      <div>{email}</div>
                      <div className="text-[9px]">Role: {role && _.replace(role, '_', ' ')}</div>
                    </div>
                    <Avatar source={photo} />
                  </Popover.Button>
                </div>
                <Popover.Panel
                  as={motion.div}
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{
                    opacity: 0,
                    scale: 0.95
                  }}
                  transition={{
                    duration: 0.2,
                    type: 'spring',
                    stiffness: 100
                  }}
                  className="text-gray-700 absolute right-0 z-10 w-screen max-w-xs origin-top-right rounded-2xl bg-white shadow-lg outline-none ring-0 focus:ring-0 focus:outline-none"
                >
                  <div className="p-4 space-y-4">
                    <div className="text-[10px] text-gray-500 text-right">{officeLocation}</div>
                    <div className="flex items-center space-x-2 text-gray-700">
                      <Avatar source={photo} />
                      <div>
                        <div className="font-semibold text-orange-400">{name}</div>
                        <div className="text-gray-400 font-medium">{email}</div>
                        <div className="text-gray-400 font-medium">{jobTitle}</div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      close();
                      if (handleLogout) {
                        handleLogout();
                      }
                    }}
                    className="text-orange-400 flex items-center space-x-2 w-full font-medium bg-orange-50 p-4 rounded-b-2xl hover:bg-orange-100 outline-none focus:outline-none right-0 focus:ring-0 transition ease-in-out duration-500"
                  >
                    <ArrowRightOnRectangleIcon className="w-5 h-5" />
                    <div>Sign Out</div>
                  </button>
                </Popover.Panel>
              </>
            )}
          </Popover>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Profile;
