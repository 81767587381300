import { CheckCircleIcon, ExclamationTriangleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react';

function Toast({
  id,
  type = 'success',
  title,
  description,
  onAccept,
  onDelete,
  onClose,
  autoClose = true,
  pauseOnHover = true
}) {
  const timeoutRef = useRef();

  useEffect(() => {
    if (autoClose && pauseOnHover) {
      timeoutRef.current = setTimeout(onClose, 5000);

      return () => clearTimeout(timeoutRef.current);
    }
  }, [autoClose, pauseOnHover]);

  const handleMouseEnter = () => {
    if (autoClose && pauseOnHover) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleMouseLeave = () => {
    if (autoClose && pauseOnHover) {
      timeoutRef.current = setTimeout(onClose, 5000);
    }
  };

  return (
    <motion.li
      key={id}
      layout
      drag
      dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
      whileHover={{ scale: 1.05, transition: { duration: 0.1 } }}
      initial={{
        opacity: 0,
        y: 50,
        scale: 0.3
      }}
      animate={{
        opacity: 1,
        y: 0,
        scale: 1,
        transition: { duration: 0.1 }
      }}
      exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
      transition={{ type: 'spring' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="w-96 bg-white shadow-lg rounded-2xl pointer-events-auto overflow-hidden"
    >
      <div className="flex p-4 space-x-4">
        <div
          className={clsx(
            type === 'error' && 'text-red-500 bg-red-100',
            type === 'success' && 'text-green-500 bg-green-100',
            type === 'warning' && 'text-amber-500 bg-amber-100',
            'inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg'
          )}
        >
          {type === 'success' && <CheckCircleIcon className="h-5 w-5" />}
          {type === 'warning' && <ExclamationTriangleIcon className="h-5 w-5" />}
          {type === 'error' && <XCircleIcon className="h-5 w-5" />}
        </div>
        <div className="flex flex-col flex-1">
          <div
            className={clsx(
              type === 'error' && 'text-red-500',
              type === 'success' && 'text-green-500',
              type === 'warning' && 'text-amber-500',
              'text-sm font-semibold'
            )}
          >
            {title}
          </div>
          <div className="text-xs text-gray-500 mb-4">{description}</div>
          <div className="flex flex-1 justify-end space-x-2">
            <button
              type="button"
              className="text-gray-500 hover:text-gray-400 bg-gray-100 text-xs items-center justify-center w-16 h-10 rounded-xl font-semibold tracking-tight outline-none focus:outline-none ring-0 focus:ring-0 transition-colors ease-in-out duration-500"
              onClick={() => {
                if (onDelete) {
                  onDelete();
                }
                onClose();
              }}
            >
              Close
            </button>
            {onAccept && (
              <button
                type="button"
                className={clsx(
                  type === 'error' && 'text-red-500 hover:text-red-400',
                  type === 'success' && 'text-green-500 hover:text-green-400',
                  type === 'warning' && 'text-amber-500 hover:text-amber-400',
                  'text-xs items-center justify-center w-16 h-10 border rounded-xl font-semibold tracking-tight outline-none focus:outline-none ring-0 focus:ring-0 transition-colors ease-in-out duration-500'
                )}
                onClick={() => {
                  if (onAccept) {
                    onAccept();
                  }
                  onClose();
                }}
              >
                Accept
              </button>
            )}
          </div>
        </div>
      </div>
    </motion.li>
  );
}

export default Toast;
