import { gql } from '@apollo/client';

export const GET_OPTIONS = gql`
  query GetOptions {
    positions {
      _id
      name
    }
    departments {
      _id
      name
    }
    devices {
      _id
      name
    }
    domains {
      _id
      name
    }
    jobs {
      _id
      name
    }
  }
`;

export const GET_REQUESTS = gql`
  query GetRequests($createdBy: String, $hrApproved: Boolean) {
    requests(createdBy: $createdBy, hrApproved: $hrApproved) {
      _id
      givenName
      surname
      createdBy
      createdAt
      jobTitle
      hrApproved
      itApproved
    }
  }
`;

export const GET_REQUEST = gql`
  query GetRequest($_id: String!) {
    request(_id: $_id) {
      _id
      givenName
      surname
      userPrincipalName
      department
      employeeType
      hierarchy
      isSupervisor
      jobTitle
      manager
      startDate
      endDate
      devices
      preferredLanguage
      usageLocation
      streetAddress
      city
      country
      officeLocation
      companyName
      postalCode
      state
      hrApproved
      hrApprover
      hrApprovedDate
      itApproved
      itApprover
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;
