import clsx from 'clsx';
import Badge from '../badge/Badge';

function Card({
  title,
  subtitle,
  image,
  date,
  author,
  content,
  badge,
  vertical = false,
  className,
  onClick
}) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        className,
        vertical ? 'flex-col' : 'p-4',
        onClick &&
          'cursor-pointer hover:shadow-xl transition-all duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none',
        'flex bg-white rounded-2xl shadow-sm text-neutral-700 border border-gray-100'
      )}
    >
      <div className={clsx('flex flex-col flex-1 space-y-2', vertical && 'p-4')}>
        {(title || subtitle) && (
          <div className={clsx(vertical ? 'mb-4' : 'flex items-center mb-4')}>
            {image && (
              <div
                className={clsx('flex flex-shrink-0 justify-center', vertical ? 'mb-8' : 'mr-4')}
              >
                <div
                  className={clsx(
                    'bg-center bg-cover bg-no-repeat shadow-xl',
                    vertical ? 'rounded-2xl w-full h-52' : 'w-16 h-16 rounded-full'
                  )}
                  style={{
                    backgroundImage: `url(${image})`
                  }}
                />
              </div>
            )}
            <div className="flex-1">
              <div className="text-base font-bold tracking-tight">{title}</div>
              <div className="text-xs text-neutral-500">{subtitle}</div>
            </div>
            {badge && <Badge text={badge} />}
          </div>
        )}
        {content && <div className="flex-1 text-sm">{content}</div>}
        {(author || date) && (
          <div className="flex flex-col items-end">
            <div className="text-xs font-medium italic text-neutral-400">{author}</div>
            <div className="text-tiny italic text-neutral-400">{date}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Card;
