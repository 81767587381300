import { gql } from '@apollo/client';

export const CREATE_DEPARTMENT = gql`
  mutation CreateDepartment($name: String!, $createdBy: String!, $updatedBy: String!) {
    createDepartment(name: $name, createdBy: $createdBy, updatedBy: $updatedBy) {
      _id
      name
      createdBy
      createdAt
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation UpdateDepartment(
    $_id: String!
    $name: String!
    $updatedBy: String!
    $updatedAt: DateTime!
  ) {
    updateDepartment(_id: $_id, name: $name, updatedBy: $updatedBy, updatedAt: $updatedAt) {
      _id
      name
      createdBy
      createdAt
    }
  }
`;

export const DELETE_DEPARTMENT = gql`
  mutation DeleteDepartment($_id: String!) {
    deleteDepartment(_id: $_id) {
      _id
      name
    }
  }
`;
