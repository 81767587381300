import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { Fragment } from 'react';
import Logo from '../../assets/img/iconOrange.svg';
import { enterFrom, leaveTo } from './utils';

function Drawer({
  open,
  onClose,
  direction = 'right',
  content,
  footer,
  dark = false,
  beforeEnter,
  afterEnter,
  beforeLeave,
  afterLeave
}) {
  return (
    <Transition.Root
      show={open}
      as={Fragment}
      beforeEnter={beforeEnter}
      afterEnter={afterEnter}
      beforeLeave={beforeLeave}
      afterLeave={afterLeave}
    >
      <Dialog
        as="div"
        static
        open={open}
        onClose={onClose}
        className={clsx('fixed z-50 inset-0 overflow-hidden', dark && 'dark')}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-50 dark:bg-opacity-80" />
          </Transition.Child>
          <div
            className={clsx(
              direction === 'right' && 'right-0 pl-10 inset-y-0',
              direction === 'left' && 'left-0 pr-10 inset-y-0',
              direction === 'top' && 'top-0 inset-x-0',
              direction === 'bottom' && 'bottom-0 inset-x-0',
              'fixed max-w-full flex'
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-150"
              enterFrom={enterFrom(direction)}
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-150"
              leaveFrom="translate-x-0"
              leaveTo={leaveTo(direction)}
            >
              <div
                className={clsx(
                  direction === 'right' && 'relative w-screen max-w-sm',
                  direction === 'left' && 'relative w-screen max-w-sm',
                  direction === 'top' && 'relative w-screen',
                  direction === 'bottom' && 'relative w-screen'
                )}
              >
                <div className="h-full flex flex-col bg-gray-900 divide-y divide-gray-700">
                  <div className="flex items-center justify-between flex-shrink-0 px-4 h-24">
                    <img className="h-10 w-auto" src={Logo} alt="Logo" />
                    <button
                      type="button"
                      className={clsx(
                        direction === 'right' && '-left-8 top-2',
                        direction === 'left' && '-right-8 top-2',
                        direction === 'top' && '-bottom-8',
                        direction === 'bottom' && '-top-8',
                        'text-white hover:opacity-95 focus:outline-none focus:ring-0'
                      )}
                      onClick={onClose}
                    >
                      <XMarkIcon
                        className="h-6 w-6 transform hover:scale-125 transition duration-500 ease-in-out"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                  {content && content}
                  {footer && footer}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Drawer;
