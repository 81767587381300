import { Combobox, Dialog, Transition } from '@headlessui/react';
import { ChevronRightIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { map } from 'lodash';
import React, { Fragment } from 'react';
import { useMount } from 'react-use';
import Spinner from '../spinner/Spinner';

function CommandPalette({
  show,
  onClose,
  value,
  onSelect,
  afterLeave,
  onChange,
  placeholder,
  options,
  loading,
  onKey,
  disabled
}) {
  useMount(() => {
    const onKeydown = (e) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        if (onKey) {
          onKey();
        }
      }
    };
    window.addEventListener('keydown', onKeydown);
    return () => {
      window.removeEventListener('keydown', onKeydown);
    };
  });
  return (
    <Transition.Root show={show && !disabled} as={Fragment} afterLeave={afterLeave}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-hidden"
        onClose={() => {
          if (onClose) {
            onClose();
          }
        }}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900/20 transition-opacity backdrop-filter backdrop-blur-sm" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-xl transform shadow-2xl divide-y divide-gray-200 overflow-hidden rounded-md bg-white ring-0 transition-all">
                <Combobox value={value} onChange={onSelect}>
                  <div className="flex">
                    <div className="inline-flex items-center justify-center bg-gray-100 border-r border-gray-200 shrink-0 h-12 w-12">
                      <MagnifyingGlassIcon className="pointer-events-none h-5 w-5 text-gray-400" />
                    </div>
                    <Combobox.Input
                      className="flex-1 text-xs px-2 border-0 ring-0 text-gray-700 outline-none focus:outline-none placeholder-gray-400 focus:ring-0"
                      placeholder={placeholder}
                      onChange={onChange}
                    />
                    <Transition
                      as="div"
                      show={loading}
                      enter="transition-opacity duration-75"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-opacity duration-150"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      className="inline-flex items-center justify-center shrink-0 h-12 w-12"
                    >
                      <Spinner className="pointer-events-none text-gray-400" />
                    </Transition>
                  </div>
                  {options?.length > 0 && value !== '' && (
                    <Combobox.Options
                      static
                      className="max-h-72 scroll-py-2 overflow-y-auto text-sm divide-y divide-gray-100 cursor-pointer"
                    >
                      {map(options, (e) => (
                        <Combobox.Option as="div" key={e.text} value={e.value}>
                          {({ active }) => (
                            <button
                              type="button"
                              className="text-left text-xs font-medium text-gray-700 hover:bg-gray-100 flex space-x-2 px-2 py-3 w-full ring-0 outline-none focus:ring-0 focus:outline-none transition-all ease-in-out duration-150"
                            >
                              {e.Icon && <e.Icon className={clsx('h-5 w-5 text-gray-400')} />}
                              <div
                                className={clsx(
                                  active && 'translate-x-1',
                                  'flex-1 transition-all ease-in-out duration-500'
                                )}
                              >
                                <div>{e.text}</div>
                                <div className="text-gray-400">{e.description}</div>
                              </div>
                              <ChevronRightIcon className={clsx('h-3 w-3 text-gray-400')} />
                            </button>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default CommandPalette;
