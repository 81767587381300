import { useMutation, useQuery } from '@apollo/client';
import { EyeIcon, TrashIcon, UserCircleIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/buttons/Button';
import Modal from '../../../components/modal/Modal';
import Table from '../../../components/table/Table';
import { useToast } from '../../../components/toast/ToastProvider';
import { Link, Subtitle, Title } from '../../../components/typography';
import { DELETE_POSITION } from '../../../graphql/positions/mutations';
import { GET_POSITION, GET_POSITIONS } from '../../../graphql/positions/queries';
import Form from './sharedComponents/Form';

function Positions() {
  const { _id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { data } = useQuery(GET_POSITIONS);
  const [open, setOpen] = useState(false);
  const [deletePosition] = useMutation(DELETE_POSITION);
  const { data: position } = useQuery(GET_POSITION, {
    variables: { _id }
  });

  const handleClick = async (id) => {
    const res = await deletePosition({
      variables: { _id: id },
      refetchQueries: [{ query: GET_POSITIONS }, 'GetPositions']
    });

    toast({
      title: res.data?.deletePosition?.error ? 'Position not deleted' : 'Position deleted',
      description:
        res.data?.deletePosition?.message ||
        `The position ${res.data?.deletePosition?.name} has been deleted successfully.`,
      type: res.data?.deletePosition?.error ? 'error' : 'success'
    });
  };

  const columns = useMemo(
    () => [
      {
        accessor: 'name',
        Header: 'Name'
      },
      {
        accessor: 'createdBy',
        Header: 'Creator',
        Cell: ({ value }) => {
          return <Link href={`mailto:${value}`} text={value} />;
        }
      },
      {
        accessor: 'createdAt',
        Header: 'Date',
        Cell: ({ value }) => {
          return <>{dayjs(value).format('LLL')}</>;
        }
      },
      {
        accessor: 'actions',
        Header: '',
        Cell: ({ row }) => {
          return (
            <div className="flex items-center justify-center space-x-2">
              <Button
                type="button"
                color="text-red-500"
                Icon={TrashIcon}
                rounded
                outline
                onClick={(e) => {
                  e.stopPropagation();
                  toast({
                    type: 'warning',
                    title: 'Delete position',
                    autoClose: false,
                    description: 'Are you sure you want to delete this position?',
                    onAccept: () => handleClick(row.original._id)
                  });
                }}
              />
              <Button
                type="button"
                color="text-sky-500"
                Icon={EyeIcon}
                rounded
                outline
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/employees/positions/${row.original._id}`);
                }}
              />
            </div>
          );
        },
        disableSortBy: true
      }
    ],
    []
  );

  useEffect(() => {
    if (_id) setOpen(true);
  }, [_id]);

  return (
    <>
      <Modal
        open={open}
        closeModal={() => setOpen(false)}
        title="Position Form"
        description="Use the following request form to create or edit a position."
        onExitComplete={() => {
          if (_id) {
            navigate('/employees/positions/');
          }
        }}
      >
        <Form onSuccess={() => setOpen(false)} data={position?.position} />
      </Modal>
      <Title text="Positions" />
      <Subtitle text="This section is intended for managing employees positions . Please use this section to create, read, update or delete a position." />
      <Table
        columns={columns}
        data={data?.positions || []}
        onRowClick={(row) => navigate(`/employees/positions/${row?._id}`)}
        toolbar={
          <div className="flex items-center">
            <Button
              label="New Position"
              Icon={UserCircleIcon}
              backgroundColor="bg-green-500"
              onClick={() => setOpen(true)}
            />
          </div>
        }
      />
    </>
  );
}

export default Positions;
