import clsx from 'clsx';

const Checkbox = ({ label, helpText, className, ...props }) => {
  return (
    <div className={clsx(className, 'flex items-center text-xs space-x-2')}>
      <input
        id={props.name}
        type="checkbox"
        {...props}
        className="w-4 h-4 text-orange-400 bg-orange-100 border-orange-200 disabled:opacity-50 disabled:cursor-not-allowed rounded-sm ring-0 outline-none focus:ring focus:ring-orange-50 focus:border-orange-300 focus:outline-none"
      />
      <div>
        <label
          htmlFor={props.name}
          className={clsx('font-semibold', props.disabled ? 'text-gray-400' : 'text-gray-700')}
        >
          {label}
        </label>
        <div id={`${props.name}-description`} className="text-xs text-gray-400">
          {helpText}
        </div>
      </div>
    </div>
  );
};

export default Checkbox;
