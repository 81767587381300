import { motion } from 'framer-motion';

function Backdrop({ children, onClick }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.1,
        ease: 'easeInOut'
      }}
      onClick={onClick}
      className="fixed inset-0 bg-gray-900/20 backdrop-filter backdrop-blur-sm"
    >
      {children}
    </motion.div>
  );
}

export default Backdrop;
