import { gql } from '@apollo/client';

export const CREATE_JOB = gql`
  mutation CreateJob($name: String!, $createdBy: String!, $updatedBy: String!) {
    createJob(name: $name, createdBy: $createdBy, updatedBy: $updatedBy) {
      _id
      name
      createdBy
      createdAt
    }
  }
`;

export const UPDATE_JOB = gql`
  mutation UpdateJob($_id: String!, $name: String!, $updatedBy: String!, $updatedAt: DateTime!) {
    updateJob(_id: $_id, name: $name, updatedBy: $updatedBy, updatedAt: $updatedAt) {
      _id
      name
      createdBy
      createdAt
    }
  }
`;

export const DELETE_JOB = gql`
  mutation DeleteJob($_id: String!) {
    deleteJob(_id: $_id) {
      _id
      name
    }
  }
`;
