import {
  BriefcaseIcon,
  ComputerDesktopIcon,
  GlobeAltIcon,
  HomeIcon,
  RectangleGroupIcon,
  UserPlusIcon,
  UsersIcon
} from '@heroicons/react/20/solid';

import Departments from '../pages/employees/departments/Departments';
import Devices from '../pages/employees/devices/Devices';
import Domains from '../pages/employees/domains/Domains';
import Employees from '../pages/employees/Employees';
import Jobs from '../pages/employees/jobs/Jobs';
import Positions from '../pages/employees/positions/Positions';
import Requests from '../pages/employees/requests/Requests';
import Home from '../pages/home/Home';

const routes = [
  {
    key: 'home',
    name: 'Home',
    params: null,
    type: 'item',
    path: '/',
    icon: HomeIcon,
    auth: ['admin', 'ceo', 'human_resources', 'staff', 'it_admin', 'user'],
    index: true,
    element: <Home />
  },
  {
    key: 'employees',
    name: 'Employees',
    path: 'employees',
    type: 'group',
    params: null,
    icon: UsersIcon,
    auth: ['admin', 'staff', 'it_admin', 'ceo', 'human_resources'],
    index: false,
    element: <Employees />,
    children: [
      {
        key: 'requests',
        name: 'Requests',
        path: '/employees/requests',
        params: '/:_id?',
        type: 'item',
        icon: UserPlusIcon,
        auth: ['admin', 'staff', 'it_admin', 'ceo', 'human_resources'],
        index: false,
        element: <Requests />
      },
      {
        key: 'departments',
        name: 'Departments',
        path: '/employees/departments',
        params: '/:_id?',
        type: 'item',
        icon: BriefcaseIcon,
        auth: ['admin', 'it_admin'],
        index: false,
        element: <Departments />
      },
      {
        key: 'positions',
        name: 'Positions',
        path: '/employees/positions',
        params: '/:_id?',
        type: 'item',
        icon: RectangleGroupIcon,
        auth: ['admin', 'it_admin'],
        index: false,
        element: <Positions />
      },
      {
        key: 'devices',
        name: 'Devices',
        path: '/employees/devices',
        params: '/:_id?',
        type: 'item',
        icon: ComputerDesktopIcon,
        auth: ['admin', 'it_admin'],
        index: false,
        element: <Devices />
      },
      {
        key: 'domains',
        name: 'Domains',
        path: '/employees/domains',
        params: '/:_id?',
        type: 'item',
        icon: GlobeAltIcon,
        auth: ['admin', 'it_admin'],
        index: false,
        element: <Domains />
      },
      {
        key: 'jobs',
        name: 'Jobs',
        path: '/employees/jobs',
        params: '/:_id?',
        type: 'item',
        icon: BriefcaseIcon,
        auth: ['admin', 'it_admin'],
        index: false,
        element: <Jobs />
      }
    ]
  }
];

export default routes;
