import { ApolloProvider } from '@apollo/client';
import { EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { RouterProvider } from 'react-router-dom';
import client from '../graphql/client';
import instance from '../providers/auth';
import router from '../router/router';
import DrawerProvider from './drawer/DrawerProvider';
import { ToastProvider } from './toast/ToastProvider';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Puerto_Rico');

const accounts = instance.getAllAccounts();

if (accounts.length > 0) {
  instance.setActiveAccount(accounts[0]);
}

instance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const { account } = event.payload;
    instance.setActiveAccount(account);
  }
});

function App() {
  return (
    <ApolloProvider client={client}>
      <MsalProvider instance={instance}>
        <ToastProvider>
          <DrawerProvider>
            <RouterProvider router={router} />
          </DrawerProvider>
        </ToastProvider>
      </MsalProvider>
    </ApolloProvider>
  );
}

export default App;
