import clsx from 'clsx';
import { map } from 'lodash';

function Select({ label, helpText, error, Icon, className, options, placeholder, ...props }) {
  return (
    <div className={clsx(className, 'flex flex-col space-y-2')}>
      <label
        htmlFor={props.name}
        className={clsx(
          props.disabled && 'text-gray-400',
          error && 'text-red-500',
          'font-semibold text-xs'
        )}
      >
        <span>{label}</span>
        {props.required && <sup className="ml-1 text-red-500">*</sup>}
      </label>
      <div className="relative flex items-center">
        {Icon && (
          <div className="px-4 flex items-center pointer-events-none absolute inset-y-0 left-0">
            <Icon
              className={clsx(error ? 'text-red-300' : 'text-orange-200', 'h-5 w-5')}
              aria-hidden="true"
            />
          </div>
        )}
        <select
          id={props.name}
          {...props}
          className={clsx(
            error
              ? 'text-red-500 border-red-200 bg-red-50 focus:ring focus:border-red-500 focus:ring-red-500/20'
              : 'text-gray-700 border-orange-200 bg-white focus:ring focus:border-orange-500 focus:ring-orange-500/20',
            'rounded-lg text-xs block w-full py-2.5 ring-0 outline-none focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed transition-all duration-300',
            Icon ? 'pl-11' : 'pl-4'
          )}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {map(options, (o) => {
            return (
              <option key={o.label} value={o.value} className="font-sans">
                {o.label}
              </option>
            );
          })}
        </select>
      </div>
      {helpText && <div className="text-gray-400 text-xs">{helpText}</div>}
      {error && <div className="text-red-500 text-xs italic">{error}</div>}
    </div>
  );
}

export default Select;
