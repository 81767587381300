import { gql } from '@apollo/client';

export const GET_DOMAINS = gql`
  query GetDomains {
    domains {
      _id
      name
      createdBy
      createdAt
    }
  }
`;

export const GET_DOMAIN = gql`
  query GetDomain($_id: String!) {
    domain(_id: $_id) {
      _id
      name
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
