import { Client } from '@microsoft/microsoft-graph-client';

export const getProfile = async (token) => {
  const client = Client.init({
    authProvider: (done) => {
      done(null, token);
    }
  });

  const profile = await client
    .api('/me')
    .select('id,displayName,jobTitle,mail,officeLocation,department,employeeType')
    .get();

  const photo = await client.api(`/users/${profile?.mail}/photo/$value`).get();

  return {
    profile,
    photo
  };
};

// export const groupMembers = async (token) => {
//   const client = Client.init({
//     authProvider: (done) => {
//       done(null, token);
//     }
//   });
//
//   const members = await client
//     .api('/groups/75ce9623-23ac-4d3a-9004-6df2df997ba0/members')
//     .top(200)
//     .count(true)
//     .get();
//
//   if (members.value) {
//     _.forEach(members.value, async ({ id }) => {
//       if (id) {
//         const res = await client
//           .api('/groups/1f24ae6a-e082-42db-af46-d85b9321e58a/members/$ref')
//           .post({
//             '@odata.id': `https://graph.microsoft.com/v1.0/directoryObjects/${id}`
//           });
//
//         console.log(res);
//       }
//     });
//   }
// };

export const searchUsers = async (token, query) => {
  const client = Client.init({
    authProvider: (done) => {
      done(null, token);
    }
  });

  return await client
    .api('/users')
    .filter(
      `startswith(displayName,'${query}') or startswith(givenName,'${query}') or startswith(surname,'${query}') or startswith(mail,'${query}') or startswith(userPrincipalName,'${query}')`
    )
    .count(true)
    .get();
};

export const createUser = async (token, user, date, manager) => {
  const client = Client.init({
    authProvider: (done) => {
      done(null, token);
    }
  });

  const res = await client.api('/users').post(user);

  if (res && date) {
    await client.api(`/users/${res.id}`).update({
      employeeHireDate: date
    });
  }

  if (res && manager) {
    await client.api(`/users/${res.id}/manager/$ref`).put({
      '@odata.id': `https://graph.microsoft.com/v1.0/users/${manager}`
    });
  }

  await client.api(`/users/${res.id}/assignLicense`).post({
    addLicenses: [
      {
        skuId: '18181a46-0d4e-45cd-891e-60aabd171b4e'
      }
    ],
    removeLicenses: []
  });
  return res;
};

export const deleteUser = (token, userPrincipalName) => {
  const client = Client.init({
    authProvider: (done) => {
      done(null, token);
    }
  });

  return client.api(`/users/${userPrincipalName}`).delete();
};

export async function sendEmail(token, subject, content, toRecipients, ccRecipients, attachments) {
  const client = Client.init({
    authProvider: (done) => {
      done(null, token);
    }
  });

  return await client.api('/me/sendMail').post({
    message: {
      subject,
      body: {
        contentType: 'Text',
        content
      },
      toRecipients,
      ccRecipients,
      attachments
    }
  });
}
