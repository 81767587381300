import { gql } from '@apollo/client';

// GET_DEPARTMENTS
export const GET_POSITIONS = gql`
  query GetPositions {
    positions {
      _id
      name
      createdBy
      createdAt
    }
  }
`;

export const GET_POSITION = gql`
  query GetPosition($_id: String!) {
    position(_id: $_id) {
      _id
      name
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
