import { gql } from '@apollo/client';

export const CREATE_REQUEST = gql`
  mutation CreateRequest(
    $givenName: String!
    $surname: String!
    $createdBy: String!
    $updatedBy: String!
    $employeeType: String
    $hierarchy: String
    $isSupervisor: Boolean
    $department: String
    $jobTitle: String
    $manager: JSON
    $startDate: String
    $endDate: String
    $devices: [String]
  ) {
    createRequest(
      givenName: $givenName
      surname: $surname
      createdBy: $createdBy
      updatedBy: $updatedBy
      employeeType: $employeeType
      hierarchy: $hierarchy
      isSupervisor: $isSupervisor
      department: $department
      jobTitle: $jobTitle
      manager: $manager
      startDate: $startDate
      endDate: $endDate
      devices: $devices
    ) {
      _id
      givenName
      surname
      manager
      createdAt
    }
  }
`;

export const UPDATE_REQUEST = gql`
  mutation UpdateRequest(
    $_id: String!
    $givenName: String
    $surname: String
    $userPrincipalName: String
    $department: String
    $employeeType: String
    $jobTitle: String
    $hierarchy: String
    $isSupervisor: Boolean
    $manager: JSON
    $startDate: String
    $endDate: String
    $devices: [String]
    $preferredLanguage: String
    $usageLocation: String
    $streetAddress: String
    $city: String
    $country: String
    $officeLocation: String
    $companyName: String
    $postalCode: String
    $state: String
    $hrApproved: Boolean
    $hrApprover: String
    $hrApprovedDate: DateTime
    $itApproved: Boolean
    $itApprover: String
    $itApprovedDate: DateTime
    $updatedBy: String
    $updatedAt: DateTime
  ) {
    updateRequest(
      _id: $_id
      givenName: $givenName
      surname: $surname
      userPrincipalName: $userPrincipalName
      department: $department
      employeeType: $employeeType
      jobTitle: $jobTitle
      hierarchy: $hierarchy
      isSupervisor: $isSupervisor
      manager: $manager
      startDate: $startDate
      endDate: $endDate
      devices: $devices
      preferredLanguage: $preferredLanguage
      usageLocation: $usageLocation
      streetAddress: $streetAddress
      city: $city
      country: $country
      officeLocation: $officeLocation
      companyName: $companyName
      postalCode: $postalCode
      state: $state
      hrApproved: $hrApproved
      hrApprover: $hrApprover
      hrApprovedDate: $hrApprovedDate
      itApproved: $itApproved
      itApprover: $itApprover
      itApprovedDate: $itApprovedDate
      updatedBy: $updatedBy
      updatedAt: $updatedAt
    ) {
      _id
      givenName
      surname
      updatedAt
    }
  }
`;

export const DELETE_REQUEST = gql`
  mutation DeleteRequest($_id: String!) {
    deleteRequest(_id: $_id) {
      _id
    }
  }
`;
