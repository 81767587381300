export const enterFrom = (dir) => {
  switch (dir) {
    case 'right':
      return 'translate-x-full';
    case 'left':
      return '-translate-x-full';
    case 'top':
      return '-translate-y-full';
    case 'bottom':
      return 'translate-y-full';
    default:
      return 'translate-x-full';
  }
};

export const leaveTo = (dir) => {
  switch (dir) {
    case 'right':
      return 'translate-x-full';
    case 'left':
      return '-translate-x-full';
    case 'top':
      return '-translate-y-full';
    case 'bottom':
      return 'translate-y-full';
    default:
      return 'translate-x-full';
  }
};
