import { useRouteError } from 'react-router-dom';

function Error() {
  const error = useRouteError();

  return (
    <div className="flex flex-col items-center justify-center bg-gray-900 h-screen w-screen text-white p-4 font-mono">
      <div className="text-2xl font-semibold">Oops!!!</div>
      <div className="mb-4">Sorry, an unexpected error has occurred.</div>
      <pre className="italic text-sm text-gray-200 bg-gray-800 rounded-2xl p-4">
        <code>Message: {JSON.stringify(error, null, 2)}</code>
      </pre>
    </div>
  );
}

export default Error;
