import { useMutation, useQuery } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import { CheckIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { AnimatePresence, motion } from 'framer-motion';
import { map } from 'lodash';
import { useParams } from 'react-router-dom';
import account from '../../../../../assets/img/account.svg';
import avatar from '../../../../../assets/img/avatar.svg';
import desktop from '../../../../../assets/img/desktop.svg';
import Button from '../../../../../components/buttons/Button';
import Card from '../../../../../components/card/Card';
import { useToast } from '../../../../../components/toast/ToastProvider';
import { UPDATE_REQUEST } from '../../../../../graphql/requests/mutations';
import { GET_REQUEST, GET_REQUESTS } from '../../../../../graphql/requests/queries';
import { deleteUser } from '../../../../../msGraph';
import Form from './sharedComponents/Form';

dayjs.extend(localizedFormat);

function informationTechnology({ onSuccess }) {
  const { _id } = useParams();
  const { toast } = useToast();
  const { instance, accounts } = useMsal();
  const [updateRequest] = useMutation(UPDATE_REQUEST);
  const { data } = useQuery(GET_REQUEST, {
    variables: { _id }
  });

  const handleDelete = () => {
    toast({
      title: 'Delete user',
      description: 'Are you sure you like to delete this user?',
      autoClose: false,
      onAccept: async () => {
        await instance
          .acquireTokenSilent({
            scopes: ['User.ReadWrite.All']
          })
          .then((r) => {
            deleteUser(r?.accessToken, data?.request.userPrincipalName)
              .then(async () => {
                const res = await updateRequest({
                  variables: {
                    _id: _id,
                    userPrincipalName: null,
                    updatedBy: accounts[0].username,
                    updatedAt: new Date(),
                    itApproved: false,
                    itApprover: null,
                    itApprovedDate: null
                  },
                  refetchQueries: [{ query: GET_REQUESTS }, 'GetRequests']
                });
                if (res) {
                  toast({
                    title: 'Employee deleted',
                    description:
                      'The employee has been deleted from Azure Active directory and the request updated.'
                  });
                  if (onSuccess) {
                    onSuccess();
                  }
                }
              })
              .catch((error) => {
                toast({
                  title: error?.code,
                  description: error?.message,
                  type: 'error'
                });
              });
          })
          .catch((err) => {
            toast({
              title: err?.code,
              description: err?.message,
              type: 'error'
            });
          });
      }
    });
  };

  return (
    <div className="space-y-4 text-gray-700">
      <Card
        image={avatar}
        badge={data?.request?.itApproved ? 'Created' : undefined}
        title={`${data?.request?.givenName} ${data?.request?.surname}`}
        subtitle={data?.request?.jobTitle}
        content={
          <dl className="divide-y divide-gray-200 text-xs">
            <div className="flex py-2 space-x-4">
              <dt className="font-medium text-gray-500 flex-1">Email</dt>
              <dd className="text-gray-900">{data?.request?.userPrincipalName}</dd>
            </div>
            <div className="flex py-2 space-x-4">
              <dt className="font-medium text-gray-500 flex-1">Manager</dt>
              <dd className="text-gray-900">{data?.request?.manager.displayName}</dd>
            </div>
            <div className="flex py-2 space-x-4">
              <dt className="font-medium text-gray-500 flex-1">Department</dt>
              <dd className="text-gray-900">{data?.request?.department}</dd>
            </div>
            <div className="flex py-2 space-x-4">
              <dt className="font-medium text-gray-500 flex-1">Title</dt>
              <dd className="text-gray-900">{data?.request?.jobTitle}</dd>
            </div>
            <div className="flex py-2 space-x-4">
              <dt className="font-medium text-gray-500 flex-1">Type</dt>
              <dd className="text-gray-900">{data?.request?.employeeType}</dd>
            </div>
            <div className="flex py-2 space-x-4">
              <dt className="font-medium text-gray-500 flex-1">Hiring</dt>
              <dd className="text-gray-900">
                {data?.request?.startDate && dayjs(data?.request?.startDate).format('LL')}
              </dd>
            </div>
            <div className="flex py-2 space-x-4">
              <dt className="font-medium text-gray-500 flex-1">Termination</dt>
              <dd className="text-gray-900">
                {data?.request?.endDate && dayjs(data?.request?.endDate).format('LL')}
              </dd>
            </div>
          </dl>
        }
        author={data?.request?.createdBy}
        date={dayjs(data?.request?.createdAt).format('LLL')}
      />
      <Card
        image={account}
        title="MS Office 365 Account"
        subtitle="Hey IT Administrator! Please use the following form to crate the new employee MS Active Directory account."
        content={<Form data={data?.request} onSuccess={onSuccess} />}
      />
      <Card
        image={desktop}
        title="Devices"
        subtitle="The new employee will need the following devices:"
        content={
          <div className="py-2">
            {map(data?.request.devices, (device) => {
              return (
                <div key={device} className="flex items-center text-xs font-semibold font-mono">
                  <CheckIcon className="text-orange-400 w-3 h-3 mr-2" />
                  <span>{device}</span>
                </div>
              );
            })}
          </div>
        }
      />
      <AnimatePresence>
        {data?.request?.itApproved && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
          >
            <Card
              title="Delete"
              subtitle="Would you like to approve or reject this employee's request?"
              content={
                <div className="flex space-x-2">
                  <Button
                    label="Delete Employee"
                    color="text-white"
                    backgroundColor="bg-red-500"
                    className="flex-1"
                    onClick={handleDelete}
                    disabled={!data?.request?.userPrincipalName}
                  />
                </div>
              }
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default informationTechnology;
