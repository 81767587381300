import { gql } from '@apollo/client';

export const CREATE_DEVICE = gql`
  mutation CreateDevice($name: String!, $createdBy: String!, $updatedBy: String!) {
    createDevice(name: $name, createdBy: $createdBy, updatedBy: $updatedBy) {
      _id
      name
      createdBy
      createdAt
    }
  }
`;

export const UPDATE_DEVICE = gql`
  mutation UpdateDevice($_id: String!, $name: String!, $updatedBy: String!, $updatedAt: DateTime!) {
    updateDevice(_id: $_id, name: $name, updatedBy: $updatedBy, updatedAt: $updatedAt) {
      _id
      name
      createdBy
      createdAt
    }
  }
`;

export const DELETE_DEVICE = gql`
  mutation DeleteDevice($_id: String!) {
    deleteDevice(_id: $_id) {
      _id
      name
    }
  }
`;
