import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { ArrowRightOnRectangleIcon, Bars4Icon } from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';
import { useOutlet } from 'react-router-dom';
import { useMount } from 'react-use';
import LogoFull from '../../../assets/img/logo.svg';
import { getProfile } from '../../../msGraph';
import Avatar from '../../avatar/Avatar';
import Drawer from '../../drawer/Drawer';
import { useDrawer } from '../../drawer/DrawerProvider';
import Navbar from '../../navbar/Navbar';
import Profile from '../../profile/Profile';
import Menu from './menu/Menu';

function Authenticated() {
  const outlet = useOutlet();
  const { instance, accounts } = useMsal();
  const [account, setAccount] = useState(null);
  const [visible, setVisible] = useState(false);
  const [URL, setURL] = useState(undefined);
  const { open, setOpen } = useDrawer();

  useMount(async () => {
    await instance
      .acquireTokenSilent({
        scopes: ['User.Read', 'User.Read.All', 'Mail.Send', 'Directory.ReadWrite.All']
      })
      .then((res) => {
        getProfile(res?.accessToken).then((profile) => {
          setAccount(profile);
        });
      })
      .catch(() => {
        instance
          .acquireTokenRedirect({
            scopes: ['User.Read', 'User.Read.All', 'Mail.Send', 'Directory.ReadWrite.All']
          })
          .then((res) => {
            getProfile(res?.accessToken).then((profile) => {
              setAccount(profile);
            });
          })
          .catch((err) => console.error(err.message));
      });
  });

  useEffect(() => {
    if (account) setVisible(true);
  }, [account]);

  useEffect(() => {
    if (account?.photo) {
      let string;
      const file = account?.photo;
      const reader = new FileReader();
      reader.onloadend = () => {
        string = reader.result;
        setURL(string);
      };
      reader.readAsDataURL(file);
    }
  }, [account?.photo]);

  return (
    <AuthenticatedTemplate>
      <Navbar
        sticky
        logo={LogoFull}
        content={
          <div className="flex items-center space-x-4">
            <Profile
              visible={visible}
              name={account?.profile?.displayName}
              email={account?.profile?.mail}
              jobTitle={account?.profile?.jobTitle}
              officeLocation={account?.profile?.officeLocation}
              role={accounts[0]?.idTokenClaims?.roles?.[0]}
              handleLogout={() => {
                instance.logoutRedirect().then(() => setAccount(null));
              }}
              photo={URL}
            />
            <button
              type="button"
              onClick={() => setOpen(!open)}
              className="hover:opacity-75 outline-none focus:outline-none right-0 focus:ring-0 transition ease-in-out duration-500"
            >
              <Bars4Icon className="w-6 h-6" />
            </button>
          </div>
        }
      />
      <Drawer
        dark
        open={open}
        onClose={() => setOpen(false)}
        content={<Menu onClick={() => setOpen(false)} />}
        footer={
          account && (
            <div>
              <div className="flex items-start space-x-2 text-xs text-gray-400 p-4">
                <Avatar source={URL} />
                <div>
                  <div className="font-medium text-white">{account?.profile?.displayName}</div>
                  <div>{account?.profile?.mail}</div>
                  <div>{account?.profile?.jobTitle}</div>
                </div>
              </div>
              <button
                type="button"
                onClick={() => {
                  instance.logoutRedirect().then(() => setAccount(null));
                }}
                className="text-white space-x-2 w-full bg-red-500 hover:bg-red-400 group flex items-center justify-center text-center px-4 py-6 text-xs font-medium outline-none ring-0 focus:outline-none focus:ring-0 transition-colors duration-500 ease-in-out"
              >
                <ArrowRightOnRectangleIcon className="w-4 h-4" />
                <span>Sign Out</span>
              </button>
            </div>
          )
        }
      />
      <div className="h-full pt-24">{outlet}</div>
    </AuthenticatedTemplate>
  );
}

export default Authenticated;
