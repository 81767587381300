import { gql } from '@apollo/client';

// GET_DEPARTMENTS
export const GET_DEPARTMENTS = gql`
  query GetDepartments {
    departments {
      _id
      name
      createdBy
      createdAt
    }
  }
`;

export const GET_DEPARTMENT = gql`
  query GetDepartment($_id: String!) {
    department(_id: $_id) {
      _id
      name
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
