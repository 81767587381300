import { useMutation } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import { PaperAirplaneIcon, UserCircleIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Button from '../../../../components/buttons/Button';
import TextField from '../../../../components/inputs/TextField';
import { useToast } from '../../../../components/toast/ToastProvider';
import { CREATE_JOB, UPDATE_JOB } from '../../../../graphql/jobs/mutations';
import { GET_JOBS } from '../../../../graphql/jobs/queries';

function Form({ onSuccess, data }) {
  const { _id } = useParams();
  const { accounts } = useMsal();
  const { toast } = useToast();

  const [createJob] = useMutation(CREATE_JOB, {
    refetchQueries: [{ query: GET_JOBS }, 'GetJobs']
  });

  const [updateJob] = useMutation(UPDATE_JOB, {
    refetchQueries: [{ query: GET_JOBS }, 'GetJobs']
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      createdBy: accounts[0]?.username,
      updatedBy: accounts[0]?.username
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter the job title name.')
    }),
    onSubmit: async (values, actions) => {
      if (_id) {
        const variables = omit(
          {
            ...values,
            _id,
            updatedAt: new Date()
          },
          'createdBy'
        );

        try {
          const result = await updateJob({ variables });
          if (result.data.updateJob) {
            if (onSuccess) {
              await onSuccess();
            }
            toast({
              title: 'Job title Updated',
              description: `The job title has been successfully updated at ${dayjs().format(
                'LLL'
              )}.`
            });
          }
        } catch (err) {
          toast({
            title: 'Error',
            description: err.message,
            type: 'error'
          });
        }
      } else {
        try {
          const result = await createJob({ variables: values });
          if (result.data.createJob) {
            toast({
              title: 'Job title created',
              description: 'The job title has been successfully created.'
            });
            if (onSuccess) {
              onSuccess();
            }
            actions.resetForm();
          }
        } catch (err) {
          toast({
            title: 'Error',
            description: err?.message,
            type: 'error'
          });
        }
      }
      actions.setSubmitting(false);
    }
  });

  useEffect(() => {
    if (data) {
      formik.setFieldValue('name', data.name);
    }
  }, [data]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      noValidate
      className="space-y-4 bg-orange-50 rounded-md p-4"
    >
      <TextField
        name="name"
        label="Job title"
        required
        placeholder="Developer.."
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
        Icon={UserCircleIcon}
        autoComplete="off"
        error={formik.touched.name && formik.errors.name ? formik.errors.name : null}
      />
      <Button
        type="submit"
        Icon={PaperAirplaneIcon}
        label="Submit"
        loading={formik.isSubmitting}
        disabled={formik.isSubmitting}
      />
    </form>
  );
}

export default Form;
