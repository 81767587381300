import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

function Logo({ source, link = '/' }) {
  const navigate = useNavigate();

  return (
    <motion.div
      key="logo"
      whileHover={{ scale: 0.9 }}
      whileTap={{ scale: 0.8 }}
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{
        opacity: 0,
        x: -50
      }}
      transition={{ duration: 0.2, type: 'spring', stiffness: 100 }}
      onClick={() => navigate(link)}
      className="outline-none focus:outline-none ring-0 focus:ring-0"
    >
      <div className="cursor-pointer ring-0 focus:ring-0 outline-none focus:outline-none">
        <img
          key="logo"
          alt="logo"
          src={source}
          className="h-10 w-auto transform hover:scale-105 transition duration-500 ease-in-out"
        />
      </div>
    </motion.div>
  );
}

export default Logo;
