import { useMutation } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import { ComputerDesktopIcon, PaperAirplaneIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Button from '../../../../components/buttons/Button';
import TextField from '../../../../components/inputs/TextField';
import { useToast } from '../../../../components/toast/ToastProvider';
import { CREATE_DEVICE, UPDATE_DEVICE } from '../../../../graphql/devices/mutations';
import { GET_DEVICES } from '../../../../graphql/devices/queries';

function Form({ onSuccess, data }) {
  const { _id } = useParams();
  const { accounts } = useMsal();
  const { toast } = useToast();
  const [createDevice] = useMutation(CREATE_DEVICE, {
    refetchQueries: [{ query: GET_DEVICES }, 'GetDevices']
  });
  const [updateDevice] = useMutation(UPDATE_DEVICE, {
    refetchQueries: [{ query: GET_DEVICES }, 'GetDevices']
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      createdBy: accounts[0]?.username,
      updatedBy: accounts[0]?.username
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter the devices name.')
    }),
    onSubmit: async (values, actions) => {
      if (_id) {
        const variables = omit(
          {
            ...values,
            _id,
            updatedAt: new Date()
          },
          'createdBy'
        );
        try {
          const result = await updateDevice({ variables });
          if (result.data.updateDevice) {
            if (onSuccess) {
              await onSuccess();
            }
            toast({
              title: 'Device Updated',
              description: `The department has been successfully updated at ${dayjs().format(
                'LLL'
              )}.`
            });
          }
        } catch (err) {
          toast({
            title: 'Error',
            description: err.message,
            type: 'error'
          });
        }
      } else {
        try {
          const result = await createDevice({ variables: values });
          if (result.data.createDevice) {
            toast({
              title: 'Device created',
              description: 'The devices has been successfully created.'
            });
            if (onSuccess) {
              onSuccess();
            }
            actions.resetForm();
          }
        } catch (err) {
          toast({
            title: 'Error',
            description: err?.message,
            type: 'error'
          });
        }
      }
      actions.setSubmitting(false);
    }
  });

  useEffect(() => {
    if (data) {
      formik.setFieldValue('name', data.name);
    }
  }, [data]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      noValidate
      className="space-y-4 bg-orange-50 rounded-md p-4"
    >
      <TextField
        name="name"
        label="Device"
        required
        placeholder="Developer.."
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
        Icon={ComputerDesktopIcon}
        autoComplete="off"
        error={formik.touched.name && formik.errors.name ? formik.errors.name : null}
      />
      <Button
        type="submit"
        Icon={PaperAirplaneIcon}
        label="Submit"
        loading={formik.isSubmitting}
        disabled={formik.isSubmitting}
      />
    </form>
  );
}

export default Form;
