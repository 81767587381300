import clsx from 'clsx';
import { getBackground, getBorder, getColor, getIndicator } from './utils';

function Badge({ text, color, background, className }) {
  return (
    <div className="inline-flex items-center justify-center">
      <div
        className={clsx(
          className,
          getColor(color),
          getBackground(background),
          getBorder(background),
          'border inline-flex items-center justify-center px-2.5 py-1 rounded-full'
        )}
      >
        <span className="relative flex h-2 w-2 mr-1">
          <span
            className={clsx(
              'animate-ping absolute inline-flex h-full w-full rounded-full opacity-75',
              getIndicator(background)
            )}
          />
          <span
            className={clsx('relative inline-flex rounded-full h-2 w-2', getIndicator(background))}
          />
        </span>
        <div className="font-medium text-xs">{text}</div>
      </div>
    </div>
  );
}

export default Badge;
