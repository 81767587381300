import clsx from 'clsx';
import { useMount } from 'react-use';
import Logo from './shared-components/Logo';
import { getColor } from './utils';

function Navbar({ logo, color = 'white', sticky, content, onMount }) {
  useMount(() => {
    if (onMount) {
      onMount();
    }
  });

  return (
    <nav
      className={clsx(
        getColor(color),
        sticky && 'fixed w-full z-10 top-0 left-0 backdrop-blur-sm',
        'flex items-center h-24 px-4 space-x-4 bg-gradient-to-r from-amber-500 to-orange-500'
      )}
    >
      {logo && <Logo source={logo} />}
      <div className="flex flex-1 items-center justify-end space-x-4 text-xs font-semibold ">
        {content}
      </div>
    </nav>
  );
}

export default Navbar;
