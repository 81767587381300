import { useMsal } from '@azure/msal-react';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { filter, includes, map } from 'lodash';

import { NavLink, useMatches } from 'react-router-dom';
import routes from '../../../../router/routes';

function Menu({ onClick }) {
  const matches = useMatches();
  const { accounts } = useMsal();

  return (
    <nav className="flex-1 scrollbar-hide text-gray-500 text-xs font-medium divide-y divide-gray-800 overflow-auto">
      {map(routes, (route) => {
        if (route.type === 'item' && includes(route.auth, accounts[0]?.idTokenClaims?.roles?.[0])) {
          return (
            <NavLink
              key={route.key}
              to={route.path}
              onClick={onClick}
              className={({ isActive }) => {
                return clsx(
                  isActive && 'text-white',
                  'hover:text-white group flex items-center p-4 outline-none ring-0 focus:outline-none focus:ring-0 transition-all duration-500 ease-in-out'
                );
              }}
            >
              <route.icon className="mr-3 flex-shrink-0 h-4 w-4" aria-hidden="true" />
              <div className="flex-1">{route.name}</div>
              <ChevronRightIcon className="flex-shrink-0 h-4 w-4" />
            </NavLink>
          );
        }
        if (includes(route.auth, accounts[0]?.idTokenClaims?.roles?.[0])) {
          return (
            <Disclosure
              key={route.key}
              defaultOpen={
                filter(matches, (obj) => {
                  return obj?.pathname === '/employees';
                }).length > 0
              }
            >
              {({ open }) => (
                <div className="text-sm">
                  <Disclosure.Button
                    className={() => {
                      return clsx(
                        filter(matches, (obj) => {
                          return obj?.pathname === '/employees';
                        }).length > 0 && 'text-white',
                        'hover:text-white flex w-full items-center justify-between bg-transparent group p-4 text-xs font-medium outline-none ring-0 focus:outline-none focus:ring-0 transition-all duration-500 ease-in-out'
                      );
                    }}
                  >
                    <div className="flex items-center space-x-2">
                      <route.icon className="h-5 w-5" />
                      <span>{route.name}</span>
                    </div>
                    <ChevronDownIcon
                      className={clsx(
                        open && 'rotate-180',
                        'h-4 w-4 transition ease-in-out duration-200'
                      )}
                    />
                  </Disclosure.Button>
                  <Transition
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 -translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-1"
                  >
                    <Disclosure.Panel className="bg-black/20 border-y border-gray-800">
                      {map(route.children, (r) => {
                        if (includes(r.auth, accounts[0]?.idTokenClaims?.roles?.[0])) {
                          return (
                            <NavLink
                              key={r.key}
                              to={r.path}
                              onClick={onClick}
                              className={({ isActive }) => {
                                return clsx(
                                  isActive && 'text-white',
                                  'hover:text-white bg-transparent group flex items-center pl-8 py-4 pr-4 text-xs font-medium outline-none ring-0 focus:outline-none focus:ring-0 transition-all duration-500 ease-in-out'
                                );
                              }}
                            >
                              <r.icon className="mr-3 flex-shrink-0 h-4 w-4" />
                              <div className="flex-1">{r.name}</div>
                              <ChevronRightIcon className="flex-shrink-0 h-4 w-4" />
                            </NavLink>
                          );
                        }
                      })}
                    </Disclosure.Panel>
                  </Transition>
                </div>
              )}
            </Disclosure>
          );
        }
        return null;
      })}
    </nav>
  );
}

export default Menu;
