export const getBackground = (color) => {
  switch (color) {
    case 'slate':
      return 'bg-slate-400';
    case 'neutral':
      return 'bg-neutral-400';
    case 'zinc':
      return 'bg-zinc-400';
    case 'gray':
      return 'bg-gray-400';
    case 'stone':
      return 'bg-stone-400';
    case 'red':
      return 'bg-red-400';
    case 'green':
      return 'bg-green-400';
    case 'rose':
      return 'bg-rose-400';
    case 'purple':
      return 'bg-purple-400';
    case 'orange':
      return 'bg-orange-400';
    case 'amber':
      return 'bg-amber-400';
    case 'yellow':
      return 'bg-yellow-400';
    case 'lime':
      return 'bg-lime-400';
    case 'emerald':
      return 'bg-emerald-400';
    case 'teal':
      return 'bg-teal-400';
    case 'cyan':
      return 'bg-cyan-400';
    case 'sky':
      return 'bg-sky-400';
    case 'blue':
      return 'bg-blue-400';
    case 'indigo':
      return 'bg-indigo-400';
    case 'pink':
      return 'bg-pink-400';
    case 'violet':
      return 'bg-violet-400';
    case 'black':
      return 'bg-black';
    case 'white':
      return 'bg-white';
    case 'transparent':
      return 'bg-transparent';
    default:
      return 'bg-orange-400';
  }
};

export const getColor = (color) => {
  switch (color) {
    case 'slate':
      return 'text-slate-200';
    case 'neutral':
      return 'text-neutral-200';
    case 'zinc':
      return 'text-zinc-200';
    case 'gray':
      return 'text-gray-200';
    case 'stone':
      return 'text-stone-200';
    case 'red':
      return 'text-red-200';
    case 'green':
      return 'text-green-200';
    case 'rose':
      return 'text-rose-200';
    case 'purple':
      return 'text-purple-200';
    case 'orange':
      return 'text-orange-200';
    case 'amber':
      return 'text-amber-200';
    case 'yellow':
      return 'text-yellow-200';
    case 'lime':
      return 'text-lime-200';
    case 'emerald':
      return 'text-emerald-200';
    case 'teal':
      return 'text-teal-200';
    case 'cyan':
      return 'text-cyan-200';
    case 'sky':
      return 'text-sky-200';
    case 'blue':
      return 'text-blue-200';
    case 'indigo':
      return 'text-indigo-200';
    case 'pink':
      return 'text-pink-200';
    case 'violet':
      return 'text-violet-200';
    case 'black':
      return 'text-black';
    case 'white':
      return 'text-white';
    case 'transparent':
      return 'text-transparent';
    default:
      return 'text-white';
  }
};

export const getSize = (size) => {
  switch (size) {
    case 'xs':
      return 'py-2';
    case 'sm':
      return 'py-2.5';
    case 'md':
      return 'py-3';
    case 'lg':
      return 'py-3.5';
    case 'xl':
      return 'py-4';
    default:
      return 'py-2.5';
  }
};
