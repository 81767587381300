import { AnimatePresence } from 'framer-motion';
import React, { createContext, useContext } from 'react';
import { createPortal } from 'react-dom';
import Toast from './sharedComponents/Toast';
import { useCreateDomElement } from './sharedComponents/useCreateDomElement';
import { useToasts } from './sharedComponents/useToasts';

export const ToastContext = createContext({
  toast: () => {},
  toasts: []
});

export function useToast() {
  return useContext(ToastContext);
}

export function ToastProvider({ children }) {
  const toastRoot = useCreateDomElement();
  const { toast, toasts } = useToasts();

  return (
    <>
      <ToastContext.Provider value={{ toast, toasts }}>{children}</ToastContext.Provider>
      {toastRoot &&
        createPortal(
          <ul className="fixed top-4 right-4 z-100 space-y-4">
            <AnimatePresence initial={false}>
              {toasts.map((t) => {
                return <Toast key={t.id} {...t} />;
              })}
            </AnimatePresence>
          </ul>,
          toastRoot
        )}
    </>
  );
}
