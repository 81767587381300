import clsx from 'clsx';
import Spinner from '../spinner/Spinner';
import { getSize } from './utils';

function Button({
  onClick,
  disabled = false,
  label,
  type = 'button',
  size = 'sm',
  rounded = false,
  loading,
  fluid,
  color = 'text-white',
  backgroundColor = 'bg-orange-400',
  className,
  Icon,
  outline = false
}) {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={clsx(
        className,
        rounded ? 'rounded-full w-8 h-8' : 'rounded-md px-4',
        outline && 'border border-gray-200 bg-transparent',
        fluid && 'flex-1',
        color,
        !outline && backgroundColor,
        getSize(size),
        !disabled && 'hover:opacity-50',
        'relative flex items-center shrink-0 text-xs justify-center font-medium transform disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none'
      )}
    >
      <div className="flex items-center justify-center">
        <Spinner className={clsx(loading ? 'visible' : 'invisible', 'absolute')} />
        <div
          className={clsx(
            loading ? 'invisible' : 'visible',
            Icon && label && 'space-x-2',
            'flex items-center justify-center'
          )}
        >
          {Icon && <Icon className="h-4 w-4" />}
          <div>{label}</div>
        </div>
      </div>
    </button>
  );
}

export default Button;
