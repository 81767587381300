import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon
} from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

function Alert({ warning, error, success, info, title, description, show }) {
  const [showing, setShowing] = useState(false);
  const getIcon = () => {
    if (warning) {
      return <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" />;
    }
    if (error) {
      return <XCircleIcon className="h-5 w-5 text-red-400" />;
    }
    if (success) {
      return <CheckCircleIcon className="h-5 w-5 text-green-400" />;
    }
    if (info) {
      return <InformationCircleIcon className="h-5 w-5 text-blue-400" />;
    }
  };

  useEffect(() => {
    setShowing(show);
  }, [show]);

  return (
    <AnimatePresence>
      {showing && (
        <motion.div
          initial={{ opacity: 0, y: 50, scale: 0.3 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{
            opacity: 0,
            scale: 0.5
          }}
          transition={{
            duration: 0.2,
            type: 'spring',
            stiffness: 100,
            delay: 0.1
          }}
          className={clsx(
            error && 'bg-red-50 border-red-200',
            warning && 'bg-yellow-50 border-yellow-200',
            success && 'bg-green-50 border-green-200',
            info && 'bg-blue-50 border-blue-200',
            'border rounded-2xl p-4'
          )}
        >
          <div className="flex space-x-2">
            <div className="flex-shrink-0">{getIcon()}</div>
            <div className="flex-1">
              <div
                className={clsx(
                  'text-xs font-medium',
                  error && 'text-red-800',
                  warning && 'text-yellow-800',
                  success && 'text-green-800',
                  info && 'text-blue-800'
                )}
              >
                {title}
              </div>
              <div
                className={clsx(
                  'text-xs mt-2',
                  error && 'text-red-700',
                  warning && 'text-yellow-700',
                  success && 'text-green-700',
                  info && 'text-blue-700'
                )}
              >
                {description}
              </div>
            </div>
            <div className="flex-shrink-0">
              <button
                type="button"
                className={clsx(
                  error && 'text-red-400 hover:text-red-500',
                  warning && 'text-yellow-400 hover:text-yellow-500',
                  success && 'text-green-400 hover:text-green-500',
                  info && 'text-blue-400 hover:text-blue-500',
                  'transition-colors duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none'
                )}
                onClick={(e) => {
                  e.preventDefault();
                  setShowing(false);
                }}
              >
                <XMarkIcon className={clsx('h-5 w-5')} />
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Alert;
