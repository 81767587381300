import _ from 'lodash';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import ProtectedRoute from '../components/protectedRoute/ProtectedRoute';
import Error from '../pages/error/Error';
import NotFound from '../pages/notFound/NotFound';

import routes from './routes';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />} errorElement={<Error />}>
      {_.map(routes, (route) => (
        <Route
          key={route.key}
          path={route.params ? `${route.path}${route.params}` : route.path}
          index={route.index}
          element={<ProtectedRoute roles={route.auth}>{route.element}</ProtectedRoute>}
        >
          {route.children &&
            route.children.map((child, index) => (
              <Route
                key={index}
                path={child.params ? `${child.path}${child.params}` : child.path}
                element={<ProtectedRoute roles={child.auth}>{child.element}</ProtectedRoute>}
              />
            ))}
        </Route>
      ))}
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

export default router;
