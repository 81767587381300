import { PublicClientApplication } from '@azure/msal-browser';

const configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
    clientId: '2f2db435-fcc8-4175-a905-4c415064ad8a',
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

const instance = new PublicClientApplication(configuration);

export default instance;
