import { gql } from '@apollo/client';

export const CREATE_DOMAIN = gql`
  mutation CreateDomain($name: String!, $createdBy: String!, $updatedBy: String!) {
    createDomain(name: $name, createdBy: $createdBy, updatedBy: $updatedBy) {
      _id
      name
      createdBy
      createdAt
    }
  }
`;

export const UPDATE_DOMAIN = gql`
  mutation UpdateDomain($_id: String!, $name: String!, $updatedBy: String!, $updatedAt: DateTime!) {
    updateDomain(_id: $_id, name: $name, updatedBy: $updatedBy, updatedAt: $updatedAt) {
      _id
      name
      createdBy
      createdAt
    }
  }
`;

export const DELETE_DOMAIN = gql`
  mutation DeleteDomain($_id: String!) {
    deleteDomain(_id: $_id) {
      _id
      name
    }
  }
`;
