import { useOutlet } from 'react-router-dom';
import { useTitle } from 'react-use';

function Employees() {
  const outlet = useOutlet();

  useTitle('Information Technology | Employees');

  return <div className="flex-1 container mx-auto bg-white text-xs px-4 py-12">{outlet}</div>;
}

export default Employees;
