import { InboxArrowDownIcon } from '@heroicons/react/20/solid';

function EmptyTable({ text, colSpan }) {
  return (
    <tr>
      <td className="h-40" colSpan={colSpan}>
        <div className="flex flex-col justify-center items-center space-y-2">
          <InboxArrowDownIcon className="w-10 h-10 text-gray-200" />
          <span className="text-xxs uppercase text-gray-300">{text}</span>
        </div>
      </td>
    </tr>
  );
}

export default EmptyTable;
