import { useEffect, useState } from 'react';

export const useCreateDomElement = () => {
  const [domElement, setDomElement] = useState(null);

  useEffect(() => {
    const element = document.createElement('div');
    document.body.prepend(element);
    setDomElement(element);

    return () => {
      document.body.removeChild(element);
    };
  }, []);

  return domElement;
};
