import { createContext, useContext, useMemo, useState } from 'react';

export const ThemeContext = createContext({
  open: false,
  setOpen: () => {}
});

function ThemeProvider({ children }) {
  const [open, setOpen] = useState(false);

  const value = useMemo(
    () => ({
      open,
      setOpen
    }),
    [open]
  );

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
}

export default ThemeProvider;

export const useDrawer = () => useContext(ThemeContext);
