import { ArrowSmallDownIcon, ArrowSmallUpIcon, ArrowsUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import _ from 'lodash';

import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import EmptyTable from './sharedComponents/EmptyTable';
import Pagination from './sharedComponents/Pagination';

function Table({ data, columns, onRowClick, toolbar }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const onChange = useAsyncDebounce((val) => {
    setGlobalFilter(val || undefined);
  }, 200);

  return (
    <div className="space-y-4">
      <div className="flex lg:items-center flex-col lg:flex-row lg:justify-between lg:space-x-2 space-y-4 lg:space-y-0">
        <div className="flex flex-1 justify-end">{toolbar && toolbar}</div>
        <div className="w-full lg:w-96">
          <input
            id="search"
            name="search"
            type="text"
            placeholder={`Filter ${preGlobalFilteredRows.length} records...`}
            onChange={(e) => onChange(e.target.value)}
            className="w-full h-full rounded-md bg-white border-orange-200 focus:border-orange-300 outline-none focus:outline-none ring-0 focus:ring-0 text-xs transition-all duration-150 ease-in-out"
          />
        </div>
      </div>
      <div className="overflow-x-auto scrollbar-hide border border-orange-100 rounded-2xl">
        <table
          {...getTableProps()}
          className="table-fixed min-w-full divide-y divide-orange-100 bg-white"
        >
          <thead className="bg-orange-50">
            {_.map(headerGroups, (headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {_.map(headerGroup.headers, (column) => {
                  return (
                    <th {...column.getHeaderProps([column.getSortByToggleProps()])}>
                      <div className="flex items-center justify-center space-x-2 py-3 text-orange-400 font-medium tracking-wide uppercase">
                        <span>{column.render('Header')}</span>
                        {column.isSorted && (
                          <span>
                            {column.isSortedDesc ? (
                              <ArrowSmallDownIcon className="w-3 h-3" />
                            ) : (
                              <ArrowSmallUpIcon className="w-3 h-3" />
                            )}
                          </span>
                        )}
                        {!column.isSorted && !column.disableSortBy && (
                          <ArrowsUpDownIcon className="w-3 h-3 text-orange-300 group-hover:opacity-100 transition-all duration-500 ease-in-out" />
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white divide-y divide-x divide-orange-100" {...getTableBodyProps()}>
            {data.length > 0 ? (
              _.map(page, (row) => {
                prepareRow(row);
                return (
                  <tr
                    className={clsx(
                      onRowClick && 'cursor-pointer hover:bg-orange-50/75 ',
                      'even:bg-orange-50 odd:bg-white text-gray-500 transition-all duration-500 ease-in-out divide-x'
                    )}
                    onClick={() => onRowClick && onRowClick(row.original)}
                    {...row.getRowProps()}
                  >
                    {_.map(row.cells, (cell) => {
                      return (
                        <td
                          className="px-6 py-4 whitespace-nowrap last:border-l first:bg-orange-50/50 first:font-medium first:text-gray-700 first:border-r border-orange-100"
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <EmptyTable colSpan={columns.length} text="No Data" />
            )}
          </tbody>
        </table>
      </div>
      {data?.length > 10 && (
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={() => nextPage()}
          canNextPage={canNextPage}
          setPageSize={setPageSize}
          state={state}
          pageOptions={pageOptions}
          pageCount={pageCount}
          total={data.length}
        />
      )}
    </div>
  );
}

export default Table;
