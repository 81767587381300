export const getBackground = (color) => {
  switch (color) {
    case 'slate':
      return 'bg-slate-400 hover:bg-slate-300';
    case 'neutral':
      return 'bg-neutral-400 hover:bg-neutral-300';
    case 'zinc':
      return 'bg-zinc-400 hover:bg-zinc-300';
    case 'gray':
      return 'bg-gray-400 hover:bg-gray-300';
    case 'stone':
      return 'bg-stone-400 hover:bg-stone-300';
    case 'red':
      return 'bg-red-400 hover:bg-red-300';
    case 'green':
      return 'bg-green-400 hover:bg-green-300';
    case 'rose':
      return 'bg-rose-400 hover:bg-rose-300';
    case 'purple':
      return 'bg-purple-400 hover:bg-purple-300';
    case 'orange':
      return 'bg-orange-400 hover:bg-orange-300';
    case 'amber':
      return 'bg-amber-400 hover:bg-amber-300';
    case 'yellow':
      return 'bg-yellow-400 hover:bg-yellow-300';
    case 'lime':
      return 'bg-lime-400 hover:bg-lime-300';
    case 'emerald':
      return 'bg-emerald-400 hover:bg-emerald-300';
    case 'teal':
      return 'bg-teal-400 hover:bg-teal-300';
    case 'cyan':
      return 'bg-cyan-400 hover:bg-cyan-300';
    case 'sky':
      return 'bg-sky-400 hover:bg-sky-300';
    case 'blue':
      return 'bg-blue-400 hover:bg-blue-300';
    case 'indigo':
      return 'bg-indigo-400 hover:bg-indigo-300';
    case 'pink':
      return 'bg-pink-400 hover:bg-pink-300';
    case 'violet':
      return 'bg-violet-400 hover:bg-violet-300';
    case 'black':
      return 'bg-black hover:bg-opacity-75';
    case 'white':
      return 'bg-white hover:bg-opacity-75';
    case 'transparent':
      return 'bg-transparent hover:bg-transparent';
    default:
      return 'bg-orange-400 hover:bg-orange-300';
  }
};

export const getColor = (color) => {
  switch (color) {
    case 'slate':
      return 'text-slate-800';
    case 'neutral':
      return 'text-neutral-800';
    case 'zinc':
      return 'text-zinc-800';
    case 'gray':
      return 'text-gray-800';
    case 'stone':
      return 'text-stone-800';
    case 'red':
      return 'text-red-800';
    case 'green':
      return 'text-green-800';
    case 'rose':
      return 'text-rose-800';
    case 'purple':
      return 'text-purple-800';
    case 'orange':
      return 'text-orange-800';
    case 'amber':
      return 'text-amber-800';
    case 'yellow':
      return 'text-yellow-800';
    case 'lime':
      return 'text-lime-800';
    case 'emerald':
      return 'text-emerald-800';
    case 'teal':
      return 'text-teal-800';
    case 'cyan':
      return 'text-cyan-800';
    case 'sky':
      return 'text-sky-800';
    case 'blue':
      return 'text-blue-800';
    case 'indigo':
      return 'text-indigo-800';
    case 'pink':
      return 'text-pink-800';
    case 'violet':
      return 'text-violet-800';
    case 'black':
      return 'text-black';
    case 'white':
      return 'text-white';
    case 'transparent':
      return 'text-transparent';
    default:
      return 'text-white';
  }
};

export const getSize = (size) => {
  switch (size) {
    case 'xs':
      return 'py-2';
    case 'sm':
      return 'py-2.5';
    case 'md':
      return 'py-3';
    case 'lg':
      return 'py-3.5';
    case 'xl':
      return 'py-4';
    default:
      return 'py-2.5';
  }
};
