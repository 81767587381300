import { useLazyQuery, useMutation } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import { Tab } from '@headlessui/react';
import { CheckBadgeIcon, EyeIcon, TrashIcon, UserPlusIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { includes, map } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import Button from '../../../components/buttons/Button';
import Modal from '../../../components/modal/Modal';
import Table from '../../../components/table/Table';
import { useToast } from '../../../components/toast/ToastProvider';
import { Link, Subtitle, Title } from '../../../components/typography';
import { DELETE_REQUEST } from '../../../graphql/requests/mutations';
import { GET_REQUESTS } from '../../../graphql/requests/queries';
import InformationTechnology from './sharedComponents/informationTechnology/InformationTechnology';
import Staff from './sharedComponents/staff/Staff';

function Requests() {
  const { _id } = useParams();
  const { accounts } = useMsal();
  const { toast } = useToast();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [getRequests, { data }] = useLazyQuery(GET_REQUESTS);
  const [deleteRequest] = useMutation(DELETE_REQUEST, {
    refetchQueries: [{ query: GET_REQUESTS }]
  });

  useTitle('Information Technology | Requests');

  const fetchRequests = () => {
    switch (accounts?.[0].idTokenClaims?.roles?.[0]) {
      case 'staff':
        return getRequests({ variables: { createdBy: accounts[0].username } });
      case 'it_admin':
        // return getRequests({ variables: { hrApproved: true } });
        return getRequests();
      default:
        return getRequests();
    }
  };

  const handleClick = async (_id) => {
    const res = await deleteRequest({ variables: { _id } });
    if (res) {
      toast({
        title: 'Request deleted',
        description: `Success! The record with ID ${_id} has been deleted.`,
        autoClose: true,
        error: false,
        pauseOnHover: true
      });
    } else {
      toast({
        title: 'Request not deleted',
        description: `Sorry, we were unable to delete the record with ID ${_id}.`,
        autoClose: true,
        error: true,
        pauseOnHover: true
      });
    }
  };

  const tabs = [
    {
      key: '0',
      title: 'Staff',
      render: <Staff onSuccess={() => setOpen(false)} />,
      disabled: !includes(
        ['admin', 'it_admin', 'ceo', 'staff'],
        accounts[0]?.idTokenClaims?.roles?.[0]
      )
    },
    // {
    //   key: '1',
    //   title: 'Human Resources',
    //   render: <HumanResources onSuccess={() => setOpen(false)} />,
    //   disabled: !includes(
    //     ['admin', 'human_resources'],
    //     accounts[0]?.idTokenClaims?.roles?.[0]
    //   ),
    // },
    {
      key: '2',
      title: 'IT Administrator',
      render: <InformationTechnology onSuccess={() => setOpen(false)} />,
      disabled: !includes(['admin', 'it_admin'], accounts[0]?.idTokenClaims?.roles?.[0])
    }
  ];

  const defaultIndex = () => {
    switch (accounts[0]?.idTokenClaims?.roles?.[0]) {
      case 'staff':
        return 0;
      case 'human_resources':
        return 0;
      case 'it_admin':
        return 2;
      default:
        return 0;
    }
  };

  useEffect(() => {
    if (_id) setOpen(true);
  }, [_id]);

  useEffect(() => {
    if (accounts.length) fetchRequests();
  }, [accounts]);

  const columns = useMemo(
    () => [
      {
        accessor: 'givenName',
        Header: 'Employee',
        Cell: ({ row }) => {
          return (
            <div className="flex items-center justify-center text-center">{`${row.original.givenName} ${row.original.surname}`}</div>
          );
        }
      },
      {
        accessor: 'jobTitle',
        Header: 'Job Title',
        Cell: ({ value }) => {
          return <div className="flex items-center justify-center text-center">{value}</div>;
        }
      },
      {
        accessor: 'createdBy',
        Header: 'Creator',
        Cell: ({ value }) => {
          return (
            <div className="flex items-center justify-center text-center">
              <Link href={`mailto:${value}`} text={value} />
            </div>
          );
        }
      },
      {
        accessor: 'createdAt',
        Header: 'Date',
        Cell: ({ value }) => {
          return (
            <div className="flex items-center justify-center text-center">
              {dayjs(value).format('MMMM DD, YYYY')}
            </div>
          );
        }
      },
      // {
      //   accessor: 'hrApproved',
      //   Header: 'Approved',
      //   Cell: ({ row }: any) => {
      //     return (
      //       <div className="flex items-center justify-center">
      //         <CheckBadgeIcon
      //           className={clsx(
      //             row.hrApproved ? 'text-green-400' : 'text-gray-400',
      //             'w-4 h-4'
      //           )}
      //         />
      //       </div>
      //     );
      //   },
      // },
      {
        accessor: 'itApproved',
        Header: 'Completed',
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <div className="flex items-center justify-center text-center">
              <CheckBadgeIcon
                className={clsx(value ? 'text-green-400' : 'text-gray-400', 'w-4 h-4')}
              />
            </div>
          );
        }
      },
      {
        accessor: 'actions',
        Header: '',
        className: 'font-bold text-center opacity-50',
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="flex items-center justify-center space-x-2">
            <Button
              type="button"
              color="text-red-500"
              Icon={TrashIcon}
              rounded
              outline
              disabled={row.original.itApproved}
              onClick={(e) => {
                e.stopPropagation();
                toast({
                  type: 'warning',
                  title: 'Delete request',
                  autoClose: false,
                  description: 'Are you sure you want to delete this request?',
                  onAccept: () => handleClick(row.original._id)
                });
              }}
            />
            <Button
              type="button"
              color="text-sky-500"
              Icon={EyeIcon}
              rounded
              outline
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/employees/requests/${row.original._id}`);
              }}
            />
          </div>
        )
      }
    ],
    []
  );

  return (
    <>
      <Modal
        open={open}
        closeModal={() => setOpen(false)}
        title="Request Form"
        description="Use the following request form to create or edit an employee request."
        onExitComplete={() => {
          if (_id) {
            navigate('/employees/requests/');
          }
        }}
      >
        <div className="w-full">
          <Tab.Group defaultIndex={defaultIndex()}>
            <Tab.List className="rounded-t-2xl bg-orange-100 p-2 ring-0 focus:ring-0 outline-none focus:outline-none">
              <div className="flex border border-orange-200 space-x-1  rounded-2xl p-2">
                {map(tabs, (tab) => (
                  <Tab
                    key={tab.key}
                    disabled={tab.disabled}
                    className={({ selected }) => {
                      return clsx(
                        'relative text-orange-400 w-full rounded-lg py-2.5 text-sm font-medium hover:shadow hover:bg-orange-50 focus:outline-none ring-0 focus:ring-0 outline-none focus:outline-none transition ease-in-out= duration-500 disabled:opacity-20 disabled:text-gray-900 disabled:cursor-not-allowed disabled:bg-transparent',
                        selected && 'bg-orange-50 text-orange-400 shadow-md shadow-orange-200'
                      );
                    }}
                  >
                    {tab.title}
                  </Tab>
                ))}
              </div>
            </Tab.List>
            <Tab.Panels className="bg-orange-50 rounded-b-2xl">
              {map(tabs, (tab) => (
                <Tab.Panel key={tab.key} className="p-4 ring-0 focus:outline-none focus:ring-0">
                  {tab.render}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </Modal>
      <Title text="Employee Requests" />
      <Subtitle text="The Employee Requests form is used in our organization to manage the creation of new user accounts in MS Office 365. Managers utilize this form to request the setup of user accounts, providing necessary information and details. The purpose of the form is to establish a standardized process for employees to request the creation of Office 365 accounts, while enabling managers and HR departments to efficiently track and manage these requests. It serves as a central tool for ensuring a streamlined and organized approach to the account creation process within the organization." />
      <Table
        columns={columns}
        data={data?.requests || []}
        onRowClick={(row) => navigate(`/employees/requests/${row?._id}`)}
        toolbar={
          <div className="flex items-center">
            <Button
              label="New Request"
              Icon={UserPlusIcon}
              backgroundColor="bg-green-500"
              onClick={() => setOpen(true)}
            />
          </div>
        }
      />
    </>
  );
}

export default Requests;
