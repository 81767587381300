import { gql } from '@apollo/client';

// GET_DEPARTMENTS
export const GET_DEVICES = gql`
  query GetDevices {
    devices {
      _id
      name
      createdBy
      createdAt
    }
  }
`;

export const GET_DEVICE = gql`
  query GetDevice($_id: String!) {
    device(_id: $_id) {
      _id
      name
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
