import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import {
  BarsArrowDownIcon,
  BriefcaseIcon,
  CalendarDaysIcon,
  IdentificationIcon,
  MagnifyingGlassIcon,
  PaperAirplaneIcon,
  UserCircleIcon,
  UserPlusIcon,
  UsersIcon
} from '@heroicons/react/20/solid';
import { BuildingOfficeIcon } from '@heroicons/react/24/solid';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { debounce, includes, map, omit } from 'lodash';
import React, { useState } from 'react';
import { isMacOs } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import { useMount } from 'react-use';
import * as Yup from 'yup';
import Alert from '../../../../../components/alert/Alert';
import Button from '../../../../../components/buttons/Button';
import Card from '../../../../../components/card/Card';
import CommandPalette from '../../../../../components/commandPalette/CommandPalette';
import Checkbox from '../../../../../components/inputs/Checkbox';
import Select from '../../../../../components/inputs/Select';
import TextField from '../../../../../components/inputs/TextField';
import { useToast } from '../../../../../components/toast/ToastProvider';
import { CREATE_REQUEST, UPDATE_REQUEST } from '../../../../../graphql/requests/mutations';
import { GET_OPTIONS, GET_REQUEST, GET_REQUESTS } from '../../../../../graphql/requests/queries';
import { searchUsers, sendEmail } from '../../../../../msGraph';

function Staff({ onSuccess }) {
  const { _id } = useParams();
  const { toast } = useToast();
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(false);
  const [val, setVal] = useState('');
  const [options, setOptions] = useState([]);
  const [show, setShow] = useState(false);
  const { data } = useQuery(GET_OPTIONS);
  const [createRequest] = useMutation(CREATE_REQUEST, {
    refetchQueries: [{ query: GET_REQUESTS }, 'GetRequests']
  });
  const [updateRequest] = useMutation(UPDATE_REQUEST, {
    refetchQueries: [{ query: GET_REQUESTS }, 'GetRequests']
  });
  const [getRequest, { data: requests }] = useLazyQuery(GET_REQUEST, {
    variables: { _id },
    fetchPolicy: 'network-only'
  });

  const formik = useFormik({
    initialValues: {
      givenName: '',
      surname: '',
      jobTitle: '',
      department: 'Administration',
      employeeType: 'Employee',
      hierarchy: 'Employee',
      isSupervisor: false,
      manager: {
        id: '',
        mail: '',
        displayName: ''
      },
      devices: [],
      startDate: '',
      endDate: '',
      createdBy: accounts[0]?.username,
      updatedBy: accounts[0]?.username
    },
    validationSchema: Yup.object({
      givenName: Yup.string().required('Please fill out this field.'),
      surname: Yup.string().required('Please fill out this field.')
    }),
    onSubmit: async (values, actions) => {
      if (_id) {
        const variables = omit(
          {
            ...values,
            _id,
            updatedAt: new Date()
          },
          'createdBy'
        );

        try {
          const result = await updateRequest({ variables });
          if (result.data.updateRequest) {
            if (onSuccess) {
              await onSuccess();
            }

            toast({
              title: 'Request Updated',
              description: `The request for ${result.data.updateRequest.givenName} ${
                result.data.updateRequest.surname
              } has been successfully updated at ${dayjs().format('LLL')}.`
            });
          }
        } catch (error) {
          toast({
            title: error?.code,
            description: error?.message,
            type: 'error'
          });
        }
      } else {
        try {
          const result = await createRequest({ variables: values });
          if (result.data.createRequest) {
            instance
              .acquireTokenSilent({
                scopes: ['Mail.Send']
              })
              .then((r) => {
                sendEmail(
                  r?.accessToken,
                  'New employee requested',
                  `A request for a new employee has been submitted. Please follow this link to access the request: ${window.location.origin}/employees/requests/${result.data.createRequest?._id}.`,
                  [
                    {
                      emailAddress: { address: 'prios@prsciencetrust.org' }
                    }
                  ]
                );

                toast({
                  title: 'The request has been successfully submitted',
                  description: `The request for ${result.data.createRequest?.givenName} ${
                    result.data.createRequest?.surname
                  } has been successfully submitted at ${dayjs(
                    result.data.createRequest.createdAt
                  ).format('LLL')}.`
                });
              });
            if (onSuccess) {
              onSuccess();
            }
            actions.resetForm();
          }
        } catch (error) {
          toast({
            title: error?.code,
            description: error?.message,
            type: 'error'
          });
        }
      }
      actions.setSubmitting(false);
    }
  });

  const search = (criteria) => {
    instance
      .acquireTokenSilent({
        scopes: ['User.Read', 'User.Read.All', 'Mail.Send', 'Directory.ReadWrite.All']
      })
      .then((r) => {
        searchUsers(r?.accessToken, criteria.toLowerCase()).then((res) => {
          if (res.value.length > 0) {
            const response = map(res.value, (value) => {
              return {
                key: value.id,
                value: {
                  id: value.id,
                  mail: value.mail,
                  displayName: value.displayName
                },
                text: value.displayName,
                description: value.mail,
                Icon: UserPlusIcon
              };
            });
            setOptions(response);
          } else {
            setOptions([]);
          }
        });
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const debouncedSearch = debounce(async (criteria) => {
    await search(criteria);
  }, 500);

  const handleChange = async (string) => {
    setLoading(true);
    setVal(string);
    if (string) {
      await debouncedSearch(string);
    } else {
      await formik.setFieldValue('manager', {
        id: '',
        mail: '',
        displayName: ''
      });
      setLoading(false);
      setOptions([]);
    }
  };

  useMount(() => {
    const onKeydown = (e) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setShow(true);
      }
    };
    window.addEventListener('keydown', onKeydown);
    return () => {
      window.removeEventListener('keydown', onKeydown);
    };
  });

  useMount(() => {
    if (_id) {
      getRequest().then((res) => {
        formik.setFieldValue('givenName', res.data.request.givenName);
        formik.setFieldValue('surname', res.data.request.surname);
        formik.setFieldValue('jobTitle', res.data.request.jobTitle);
        formik.setFieldValue('manager.id', res.data.request.manager?.id);
        formik.setFieldValue('manager.mail', res.data.request.manager?.mail);
        formik.setFieldValue('manager.displayName', res.data.request.manager?.displayName);
        formik.setFieldValue('employeeType', res.data.request.employeeType);
        formik.setFieldValue('department', res.data.request.department);
        formik.setFieldValue('startDate', res.data.request.startDate);
        formik.setFieldValue('endDate', res.data.request.endDate);
        formik.setFieldValue('devices', res.data.request.devices);
        formik.setFieldValue('createdBy', res.data.request.createdBy);
        formik.setFieldValue('updatedBy', accounts[0].username);
      });
    }
  });

  return (
    <>
      <CommandPalette
        placeholder="Try typing manager email or e-mail..."
        value={val}
        show={show}
        options={options}
        loading={loading}
        disabled={requests?.request.hrApproved || requests?.request.itApproved}
        onSelect={(e) => {
          setOptions([]);
          setShow(false);
          formik.setFieldValue('manager', e);
        }}
        afterLeave={() => {
          setOptions([]);
          setVal('');
        }}
        onClose={() => setShow(false)}
        onChange={(event) => {
          const value = event?.target?.value ?? '';
          handleChange(value);
        }}
      />
      <Card
        content={
          <div className="space-y-4 text-gray-700">
            <form onSubmit={formik.handleSubmit} noValidate className="space-y-4">
              <Alert
                warning
                show={requests?.request.hrApproved}
                title="Request Approved"
                description="This employee request has been approved by Human Resources. If you need to edit this request the HR department must unapproved this request first."
              />
              <Alert
                success
                show={requests?.request.itApproved}
                title="Employee Created"
                description="The employee account has been created by the IT personal. If you need to edit this request the HR department must unapproved this request first and the IT department must delete the account."
              />
              <div className="text-sm font-bold tracking-tight">Employee Information</div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <TextField
                  id="givenName"
                  name="givenName"
                  label="Name"
                  type="text"
                  required
                  disabled={requests?.request.hrApproved || requests?.request.itApproved}
                  placeholder="Juan..."
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.givenName}
                  Icon={IdentificationIcon}
                  autoComplete="off"
                  error={
                    formik.touched.givenName && formik.errors.givenName
                      ? formik.errors.givenName
                      : null
                  }
                />
                <TextField
                  id="surname"
                  name="surname"
                  label="Last Name"
                  type="text"
                  required
                  autoComplete="off"
                  disabled={requests?.request.hrApproved || requests?.request.itApproved}
                  placeholder="Del Pueblo..."
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.surname}
                  Icon={IdentificationIcon}
                  error={
                    formik.touched.surname && formik.errors.surname ? formik.errors.surname : null
                  }
                />
                <Select
                  id="jobTitle"
                  name="jobTitle"
                  label="Job Title"
                  placeholder="Select..."
                  disabled={requests?.request.hrApproved || requests?.request.itApproved}
                  value={formik.values.jobTitle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  options={map(data?.jobs, (e) => {
                    return {
                      label: e.name,
                      value: e.name
                    };
                  })}
                  Icon={BriefcaseIcon}
                />
                <TextField
                  name="manager"
                  label="Manager"
                  type="text"
                  disabled={requests?.request.hrApproved || requests?.request.itApproved}
                  placeholder="Choose manager..."
                  autoComplete="off"
                  readOnly
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.manager.displayName}
                  Icon={MagnifyingGlassIcon}
                  onClick={() => setShow(true)}
                  command={isMacOs ? '⌘K' : 'Ctrl K'}
                />
                <Select
                  id="employeeType"
                  name="employeeType"
                  label="Employment Type"
                  disabled={requests?.request.hrApproved || requests?.request.itApproved}
                  value={formik.values.employeeType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  options={map(data?.positions, (e) => {
                    return {
                      label: e.name,
                      value: e.name
                    };
                  })}
                  Icon={UserCircleIcon}
                />
                <Select
                  id="department"
                  name="department"
                  label="Department"
                  Icon={BuildingOfficeIcon}
                  disabled={requests?.request.hrApproved || requests?.request.itApproved}
                  value={formik.values.department}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  options={map(data?.departments, (e) => {
                    return {
                      label: e.name,
                      value: e.name
                    };
                  })}
                />
                <Select
                  name="isSupervisor"
                  label="Is supervisor?"
                  Icon={UsersIcon}
                  disabled={requests?.request.hrApproved || requests?.request.itApproved}
                  value={formik.values.isSupervisor.toString()}
                  onChange={(event) => {
                    const value = event.target.value === 'true';
                    formik.setFieldValue('isSupervisor', value);
                  }}
                  onBlur={formik.handleBlur}
                  options={[
                    {
                      label: 'No',
                      value: 'false'
                    },
                    {
                      label: 'Yes',
                      value: 'true'
                    }
                  ]}
                  className="col-span-1 lg:col-span-2"
                />
                <Select
                  name="hierarchy"
                  label="Hierarchy"
                  Icon={BarsArrowDownIcon}
                  disabled={requests?.request.hrApproved || requests?.request.itApproved}
                  value={formik.values.hierarchy}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  options={[
                    {
                      label: 'Employee',
                      value: 'Employee'
                    },
                    {
                      label: 'Supervisor',
                      value: 'Supervisor'
                    }
                  ]}
                  className="hidden"
                />
                <TextField
                  id="startDate"
                  name="startDate"
                  label="Hiring Date"
                  type="text"
                  autoComplete="off"
                  disabled={requests?.request.hrApproved || requests?.request.itApproved}
                  placeholder="03/22/2023"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.startDate}
                  Icon={CalendarDaysIcon}
                  helpText="Date format should be: mm/dd/yyyy. Example: 03/22/2023"
                />
                <TextField
                  id="endDate"
                  name="endDate"
                  label="Termination Date"
                  type="text"
                  autoComplete="off"
                  disabled={requests?.request.hrApproved || requests?.request.itApproved}
                  placeholder="03/22/2025"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.endDate}
                  Icon={CalendarDaysIcon}
                  helpText="Date format should be: mm/dd/yyyy. Example: 03/22/2025"
                />
              </div>
              <div>
                <div className="text-sm font-bold tracking-tight">Information technology</div>
                <div className="text-sm text-gray-500">
                  Please, select the devices the employee needs.
                </div>
              </div>
              <div className="space-y-2">
                {map(data?.devices, (e) => {
                  return (
                    <Checkbox
                      key={e._id}
                      id={e._id}
                      name="devices"
                      label={e.name}
                      value={e.name}
                      disabled={requests?.request.hrApproved || requests?.request.itApproved}
                      checked={includes(formik.values.devices, e.name)}
                      onChange={formik.handleChange}
                    />
                  );
                })}
              </div>
              <div className="py-4">
                <Button
                  type="submit"
                  Icon={PaperAirplaneIcon}
                  label="Submit"
                  loading={formik.isSubmitting}
                  disabled={
                    formik.isSubmitting ||
                    requests?.request.hrApproved ||
                    requests?.request.itApproved
                  }
                />
              </div>
            </form>
          </div>
        }
      />
    </>
  );
}

export default Staff;
