import Placeholder from '../../assets/img/avatar.svg';

function Avatar({ source }) {
  return (
    <div className="flex items-center justify-center h-10 w-10 flex-shrink-0 bg-white border border-dashed rounded-full">
      <img alt="Avatar" src={source || Placeholder} className="h-10 w-10 rounded-full" />
    </div>
  );
}

export default Avatar;
