import { Listbox, Transition } from '@headlessui/react';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/react/20/solid';
import clsx from 'clsx';
import _ from 'lodash';
import { Fragment } from 'react';

function Pagination({
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  state,
  pageOptions,
  pageCount,
  setPageSize,
  total
}) {
  const rowMin = state.pageIndex * state.pageSize + 1;
  const rowMax = Math.min((state.pageIndex + 1) * state.pageSize, total);

  return (
    <div className="flex items-center gap-x-2 justify-end text-xs text-orange-400 ">
      <div>Rows per page</div>
      <Listbox value={state.pageSize} onChange={(e) => setPageSize(Number(e))}>
        <div className="relative">
          <Listbox.Button className="relative flex items-center gap-x-0.5 font-semibold">
            <span>{state.pageSize}</span>
            <ChevronDownIcon className="w-3 h-3" />
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 bg-white shadow rounded max-h-60 overflow-auto ring-0 focus:outline-none">
              {_.map([5, 10, 20], (pageSize) => (
                <Listbox.Option
                  key={pageSize}
                  value={pageSize}
                  className={clsx(
                    state.pageSize === pageSize && 'bg-orange-50',
                    'flex items-center justify-center cursor-pointer hover:bg-orange-50 first:rounded last:rounded p-2'
                  )}
                >
                  {pageSize}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      <div>
        {rowMin}-{rowMax} of {total}
      </div>
      <nav className="flex space-x-2">
        <ul className="flex items-center justify-center lg:justify-end space-x-2">
          <button
            type="button"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="flex items-center justify-center w-6 h-6 text-xs text-orange-400 border border-orange-200 disabled:opacity-50 hover:scale-105 rounded-full outline-none ring-0 focus:ring-0 focus:outline-none transition ease-in-out duration-150"
          >
            <ChevronDoubleLeftIcon className="h-4 w-4" />
          </button>
          <button
            type="button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="flex items-center justify-center w-6 h-6 text-xs text-orange-400 border border-orange-200 disabled:opacity-50 hover:scale-105 rounded-full outline-none ring-0 focus:ring-0 focus:outline-none transition ease-in-out duration-150"
          >
            <ChevronLeftIcon className="h-4 w-4" />
          </button>
          {_.map(pageOptions, (n) => (
            <li key={n}>
              <button
                type="button"
                className={clsx(
                  state.pageIndex === n
                    ? 'bg-orange-400 text-white'
                    : 'bg-white text-orange-400 border border-orange-200',
                  'text-xs font-semibold flex items-center justify-center w-6 h-6 ring-0 hover:scale-105 rounded-full outline-none focus:ring-0 focus:outline-none transition ease-in-out duration-150'
                )}
                onClick={() => gotoPage(n)}
              >
                {n + 1}
              </button>
            </li>
          ))}
          <button
            type="button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="flex items-center justify-center w-6 h-6 text-xs text-orange-400 border border-orange-200 disabled:opacity-50 hover:scale-105 rounded-full outline-none ring-0 focus:ring-0 focus:outline-none transition ease-in-out duration-150"
          >
            <ChevronRightIcon className="h-4 w-4" />
          </button>
          <button
            type="button"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="flex items-center justify-center w-6 h-6 text-xs text-orange-400 border border-orange-200 disabled:opacity-50 hover:scale-105 rounded-full outline-none ring-0 focus:ring-0 focus:outline-none transition ease-in-out duration-150"
          >
            <ChevronDoubleRightIcon className="h-4 w-4" />
          </button>
        </ul>
      </nav>
    </div>
  );
}

export default Pagination;
