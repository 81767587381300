import clsx from 'clsx';

export function Title({ text, className }) {
  return <h1 className={clsx(className, 'text-3xl font-extrabold tracking-tight mb-4')}>{text}</h1>;
}

export function Subtitle({ text, className }) {
  return <h3 className={clsx(className, 'text-base text-gray-400 mb-4')}>{text}</h3>;
}

export function Link({ className, text, ...props }) {
  return (
    <a
      href={props.href}
      className={clsx(
        className,
        'font-medium text-sky-500 hover:text-sky-400 transition-colors ease-in-out duration-150'
      )}
    >
      {text}
    </a>
  );
}
