import { gql } from '@apollo/client';

export const CREATE_POSITION = gql`
  mutation CreatePosition($name: String!, $createdBy: String!, $updatedBy: String!) {
    createPosition(name: $name, createdBy: $createdBy, updatedBy: $updatedBy) {
      _id
      name
      createdBy
      createdAt
    }
  }
`;

export const UPDATE_POSITION = gql`
  mutation UpdatePosition(
    $_id: String!
    $name: String!
    $updatedBy: String!
    $updatedAt: DateTime!
  ) {
    updatePosition(_id: $_id, name: $name, updatedBy: $updatedBy, updatedAt: $updatedAt) {
      _id
      name
      createdBy
      createdAt
    }
  }
`;

export const DELETE_POSITION = gql`
  mutation DeletePosition($_id: String!) {
    deletePosition(_id: $_id) {
      _id
      name
    }
  }
`;
