import clsx from 'clsx';

import { isDesktop } from 'react-device-detect';

function TextField({ label, helpText, error, Icon, className, command, ...props }) {
  return (
    <div className={clsx(className, 'flex flex-col text-xs space-y-2')}>
      {label && (
        <label
          htmlFor={props.name}
          className={clsx(
            props.disabled && 'text-gray-400',
            error && 'text-red-500',
            'font-semibold'
          )}
        >
          <span>{label}</span>
          {props.required && <sup className="ml-1 text-red-500">*</sup>}
        </label>
      )}
      <div className="relative flex items-center">
        {Icon && (
          <div className="px-4 flex items-center pointer-events-none absolute inset-y-0 left-0">
            <Icon
              className={clsx(error ? 'text-red-300' : 'text-orange-200', 'h-5 w-5')}
              aria-hidden="true"
            />
          </div>
        )}
        <input
          id={props.name}
          type="text"
          {...props}
          className={clsx(
            error
              ? 'border-red-200 bg-red-50 placeholder-red-400 focus:ring focus:border-red-500 focus:ring-red-500/20'
              : 'border-orange-200 bg-white placeholder-gray-400 focus:ring focus:border-orange-500 focus:ring-orange-500/20',
            'rounded-lg font-medium text-xs text-gray-700 block w-full py-2.5 ring-0 outline-none focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed transition-all duration-300',
            Icon ? 'pl-11' : 'pl-4',
            command ? 'pr-11' : 'pr-4'
          )}
        />
        {command && isDesktop && !props.disabled && (
          <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
            <kbd className="inline-flex items-center rounded border border-gray-200 px-2 text-sm font-medium text-gray-400 bg-gray-100">
              {command}
            </kbd>
          </div>
        )}
      </div>
      {helpText && <div className="text-gray-400">{helpText}</div>}
      {error && <div className="text-red-500 text-xs italic">{error}</div>}
    </div>
  );
}

export default TextField;
